/* eslint-disable max-len */
const ShareIcon = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 3.42857C16 5.32143 14.4643 6.85714 12.5714 6.85714C11.5393 6.85714 10.6143 6.4 9.98571 5.67857L6.78214 7.28214C6.83214 7.51429 6.825 7.75357 6.825 7.96786C6.825 8.24643 6.83214 8.48571 6.78214 8.71786L9.98571 10.2893C10.6143 9.6 11.5393 9.11071 12.5714 9.11071C14.4643 9.11071 16 10.6464 16 12.5714C16 14.4643 14.4643 16 12.5714 16C10.6464 16 9.14286 14.4643 9.14286 12.5714C9.14286 12.325 9.16786 12.0857 9.21786 11.8536L6.01429 10.25C5.38571 10.9714 4.46071 11.4286 3.42857 11.4286C1.535 11.4286 0 9.89286 0 7.96786C0 6.075 1.535 4.53929 3.42857 4.53929C4.46071 4.53929 5.38571 5.02857 6.01429 5.71786L9.21786 4.14643C9.16786 3.91429 9.14286 3.675 9.14286 3.42857C9.14286 1.535 10.6464 0 12.5714 0C14.4643 0 16 1.535 16 3.42857ZM3.39643 9.71429C4.375 9.71429 5.11071 8.94643 5.11071 8C5.11071 7.05357 4.375 6.28571 3.39643 6.28571C2.48179 6.28571 1.68214 7.05357 1.68214 8C1.68214 8.94643 2.48179 9.71429 3.39643 9.71429ZM12.5714 1.71429C11.625 1.71429 10.8571 2.48214 10.8571 3.42857C10.8571 4.375 11.625 5.14286 12.5714 5.14286C13.5179 5.14286 14.2857 4.375 14.2857 3.42857C14.2857 2.48214 13.5179 1.71429 12.5714 1.71429ZM12.5714 14.2857C13.5179 14.2857 14.2857 13.5179 14.2857 12.5714C14.2857 11.625 13.5179 10.8571 12.5714 10.8571C11.625 10.8571 10.8571 11.625 10.8571 12.5714C10.8571 13.5179 11.625 14.2857 12.5714 14.2857Z" />
  </svg>
);
export default ShareIcon;
