import React, { useState, useEffect } from "react";
import { Form, Input, Select, Switch } from "antd";
import { AccordionBox, FormActions, LabelBox } from "..";
import { inputFieldRule } from "../../../../../../utils";
import { useSelector } from "react-redux";
import { Button } from "../../../../../../components";
import { CustomDispatch } from "../../../../../../helpers";
import { editProjectRequest } from "../../../../../../redux/slicers/project";
import { manipulateProjectDetailPayload } from "../../../../../../data-manipulator/project";
import { DEFAULT_PROJECT_GROUP } from "../../../../../../constants";

const DetailAccordion = ({ deleteHandler, changeTab }) => {
  // const modelOptions = useSelector(({ general }) => general.models);
  // console.log(modelOptions)
  const PERSONAS = [
    {
      label: "Alex",
      value: "67fb9278-e049-4937-9af1-d771b88b6875",
    },
    {
      label: "Maya",
      value: "1a5588b4-a717-468c-ad8b-03b323e78e78",
    },
    {
      label: "Leo",
      value: "773a8ca8-efd8-4449-9305-8b8bc1591475",
    },
    {
      label: "Sophie",
      value: "ebc07e30-64a2-40e3-9584-28f44d62ee0c",
    },
    {
      label: "Aria",
      value: "1e9a0de0-1190-4ede-b946-ff451430848e",
    },
  ];

  // STATES
  const [switchFields, setswitchFields] = useState({
    enableHistory: true,
    enableAvatar: false,
    docUpload: false,
    voiceMode: false,
    saveAsTemplate: false,
    shareTemplate: false,
  });
  const [resetPreview, setResetPreview] = useState(false);
  const [applyPreview, setapplyPreview] = useState(false);

  // REDUX DATA
  const groups = useSelector(({ groups }) => groups.groups);

  // CONST VALS
  const [form] = Form.useForm();
  const sortedGroups = [...groups].sort((a, b) => a.name.localeCompare(b.name));

  // CUSTOM DISPATCH
  const [editProject, isLoading] = CustomDispatch(editProjectRequest);

  // HELPERS
  const setFieldsValuesHelper = () => {
    form.setFieldsValue(data);
    setswitchFields({
      ...switchFields,
      enableHistory: data.enableHistory,
      enableAvatar: data.enableAvatar,
      docUpload: data.docUpload,
      voiceMode: data.voiceMode,
    });
  };
  const editProjectHelper = (values) => {
    const details = {
      ...values,
      ...switchFields,
      id: data.id,
    };
    const payload = {
      resource: "project",
      method: "update",
      details: manipulateProjectDetailPayload(details),
    };
    editProject({
      payload,
      callback: (status) => {
        setResetPreview(!status);
        setapplyPreview(!status);
      },
    });
  };

  // REDUX DATA
  const data = useSelector(({ project }) => project.selectedProject);

  // HANDLERS
  const fieldChangeHandler = (status = true) => {
    setResetPreview(status);
    setapplyPreview(status);
  };
  const switchFieldsHandler = (name) => {
    fieldChangeHandler();
    setswitchFields({ ...switchFields, [name]: !switchFields[name] });
  };
  const resetHandler = () => {
    setFieldsValuesHelper();
    fieldChangeHandler(false);
  };
  const applyHandler = () => {
    form
      .validateFields()
      .then((values) => {
        fieldChangeHandler(false);
        editProjectHelper(values);
      })
      .catch(() => {});
  };

  // HOOKS
  useEffect(() => {
    if (data) setFieldsValuesHelper();
  }, [data]);

  return (
    <AccordionBox title="Project" preview>
      <Form
        form={form}
        className="customize-form"
        onFieldsChange={() => fieldChangeHandler()}
      >
        {/* PROJECT BASIC SETTING */}
        {/* <div className="basic-settings">
          <h3 className="title">
            Basic settings
            <button type="button" onClick={changeTab}>
              Edit
            </button>
          </h3>
          <div className="detail inline">
            <p>Title: </p>
            <h4>{data.projectName}</h4>
          </div>
          <div className="detail">
            <h4>Custom instruction:</h4>
            <p>{data.systemPrompt}</p>
          </div>
        </div>
        <hr /> */}
        {/* PROJECT DESCRIPTION */}
        <LabelBox
          title="Project description"
          name="projectDescription"
          message={
            "Provide a brief description of your project. " +
            "This helps others understand its purpose and key objectives."
          }
        />
        <Form.Item
          name="projectDescription"
          rules={inputFieldRule({
            name: "Project description",
            isMax: true,
            max: 240,
          })}
        >
          <Input.TextArea id="projectDescription" rows={5} />
        </Form.Item>
        <hr />
        {/* GROUP */}
        <LabelBox
          title="Group"
          name="group"
          message={"Select the group with which the project is associated."}
        />
        <Form.Item name="group">
          <Select
            id="group"
            getPopupContainer={(trigger) => trigger.parentNode}
            placeholder="Select group"
          >
            {[DEFAULT_PROJECT_GROUP, ...sortedGroups].map((data, index) => (
              <Select.Option value={data.id} key={index}>
                {data.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <hr />
        {/* CHAT MEMORY */}
        <div className="label-box">
          <LabelBox
            name="enableHistory"
            title="Enable chat memory"
            message={
              "Enabling chat memory saves past conversations with the project" +
              " to give contextually relevant responses."
            }
          />
          <Switch
            id="enableHistory"
            checked={switchFields.enableHistory}
            onChange={() => switchFieldsHandler("enableHistory")}
          />
        </div>
        <hr />
        {!!data.avatar_visibility && (
          <>
            <div className="label-box">
              <LabelBox
                name="enableAvatar"
                title="Enable avatar"
                message={
                  "By enabling avatar, you can communicate with a live avatar " +
                  "either through text or through speech."
                }
              />
              <Switch
                id="enableHistory"
                checked={switchFields.enableAvatar}
                onChange={() => switchFieldsHandler("enableAvatar")}
              />
            </div>
            <hr />
            <div className="label-box">
              <LabelBox
                title="Select an avatar"
                name="avatar"
                message="Select an avatar for your project"
              />
              <Form initialValues={{ value: "Alexx" }}>
                <Form.Item
                  name="model"
                  rules={inputFieldRule({
                    name: "Avatar",
                    isWhiteSpace: false,
                  })}
                >
                  <Select
                    showSearch
                    id="model"
                    options={PERSONAS}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    placeholder="Select avatar"
                    defaultValue="Alex"
                  />
                </Form.Item>
              </Form>
            </div>
            <hr />
          </>
        )}
        {/* DOC UPLOAD */}
        <div className="label-box">
          <LabelBox
            name="docUpload"
            title="Enable file upload"
            message="Allow end-users to upload files as a viewer."
          />
          <Switch
            id="docUpload"
            checked={switchFields.docUpload}
            onChange={() => switchFieldsHandler("docUpload")}
          />
        </div>
        <hr />
        {/* VOICE MODE */}
        {/* <div className="label-box">
          <LabelBox
            name="voiceMode"
            title="Enable voice mode"
            message="Allows end-users to use voice mode in the viewer."
          />
          <Switch
            id="voiceMode"
            checked={switchFields.voiceMode}
            onChange={() => switchFieldsHandler("voiceMode")}
          />
        </div>
        <hr /> */}
        {/* SAVE AS TEMPLATE */}
        {/* <div
          className={clsx(
            "label-box",
            switchFields.saveAsTemplate && "form-item"
          )}
        >
          <LabelBox
            name="saveAsTemplate"
            title="Save as template"
            message="Save this project as template for future use"
          />
          <Switch
            id="saveAsTemplate"
            checked={switchFields.saveAsTemplate}
            onChange={() => switchFieldsHandler("saveAsTemplate")}
          />
        </div>
        {switchFields.saveAsTemplate && (
          <Form.Item
            name="templateName"
            rules={inputFieldRule({
              name: "Template name",
              isMax: true,
              max: 120,
            })}
          >
            <Input placeholder="Template name" />
          </Form.Item>
        )} */}
        {/* SHARE TEMPLATE */}
        {/* <div className="label-box">
          <LabelBox
            name="shareTemplate"
            title="Share template"
            message="share this template with other users"
          />
          <Switch
            id="shareTemplate"
            checked={switchFields.shareTemplate}
            onChange={() => switchFieldsHandler("shareTemplate")}
          />
        </div> */}
        <div className="other-actions">
          <h4>Delete project</h4>
          <p>
            Permanently delete your project and all of its assets. This action
            can not be undone.
          </p>
          <Button className="delete-btn" onClick={deleteHandler}>
            Delete this project
          </Button>
        </div>
        <FormActions
          applyLoader={isLoading}
          resetPreview={resetPreview}
          applyPreview={applyPreview}
          resetHandler={resetHandler}
          applyHandler={applyHandler}
        />
      </Form>
    </AccordionBox>
  );
};

export default DetailAccordion;
