import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./styles.scss";

const Header = ({ onSearch }) => {
  const handleChange = (e) => {
    onSearch(e.target.value.toLowerCase());
  };

  return (
    <div className="header customize-form">
      <h1>Projects</h1>
      <Input
        className="search-box"
        placeholder="Search"
        onChange={handleChange}
        prefix={
          <SearchOutlined
            style={{
              color: "rgba(0,0,0,.25)",
            }}
          />
        }
      />
    </div>
  );
};

export default Header;
