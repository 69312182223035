import React, { useState } from "react";
import { LabelBox } from "..";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

const WebInterface = ({ data }) => {
  // STATES
  const [isCopied, setCopied] = useState(false);

  // HANDLERS
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <>
      <p className="sub-description">
      Add ASURITE IDs to give access from the 'Share' menu. By default, projects are private.
      </p>
      {/* PROJECT URL */}
      <div className="project-url-box">
        <LabelBox heading title="App URL" />
        <div className="project-url">
          <a
            className="url"
            target="_blank"
            rel="noreferrer"
            href={data?.publicUrl}
          >
            {data.publicUrl}
          </a>
          <CopyToClipboard text={data.publicUrl} onCopy={handleCopy}>
            <button type="button" className={clsx(isCopied && "active")}>
              <FontAwesomeIcon icon={isCopied ? faCircleCheck : faCopy} />
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </>
  );
};

export default WebInterface;
