import NoMedia from "../assets/icons/no-media.svg";
import CourseMaterial from "../assets/icons/course_mat.svg";
import DocTool from "../assets/icons/doc_tool.svg";
import Exploration from "../assets/icons/exploration.svg";
import Operations from "../assets/icons/operations.svg";
import StudentEngagement from "../assets/icons/stu_engagement.svg";
import Voice from "../assets/icons/voice.png";

const Icons = {
  NoMedia,
  CourseMaterial,
  DocTool,
  Exploration,
  Operations,
  StudentEngagement,
  Voice,
};

export default Icons;
