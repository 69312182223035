import qs from "qs";
import _ from "lodash";
import ApiHandler from "../services/api-handler";
import { getAppEnv, getCurrentAccessToken } from "../utils";

// TIMOUT CONFIG
export const API_TIMEOUT = 29000;
export const SOCKET_TIMEOUT = 600000;

// API ERROR MESSAGES
export const ABORT_REQUEST_MESSAGE = "Network failed. Aborted request.";
export const ERROR_SOMETHING_WENT_WRONG =
  "Something went wrong, Please try again later";
export const ERROR_API_NOT_FOUND = "Api not found, Please try again later";

export const ERROR_NETWORK_NOT_AVAILABLE =
  "Please connect to the working Internet";

export const ERROR_ACCOUNT_BLOCKED =
  "Either your account is blocked or deleted";

export const ERROR_TOKEN_EXPIRE = "Session Expired, Please login again!";

// REQUEST TYPES
export const REQUEST_TYPE = {
  GET: "get",
  POST: "post",
  DELETE: "delete",
  PUT: "put",
};

// GENERAL ROUTES
export const GENERATE_UPLOAD_URL = {
  route: "media",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

export const UPLOAD_MEDIA = {
  route: "",
  access_token_required: false,
  type: REQUEST_TYPE.POST,
  baseurl: "",
};

export const MODELS = {
  route: "model",
  access_token_required: true,
  type: REQUEST_TYPE.GET,
};

// USER ROUTES
export const GET_USER_DETAIL = {
  route: "project",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

// CHAT ROUTES
export const CHAT = {
  route: "chat",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

// PROJECT ROUTES
export const PROJECT = {
  route: "project",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

// FEEDBACK ROUTES
export const FEEDBACK = {
  route: "feedback",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

// GROUPS ROUTES
export const GROUP = {
  route: "project",
  access_token_required: true,
  type: REQUEST_TYPE.POST,
};

// CALL REQUEST TO API HANDLER WITH DEFAULT CONFIG
export const callRequest = async ({
  url,
  payload = {},
  queryParams,
  pathParams,
  header = {},
  baseURL = getAppEnv().appUrl,
  cacheBusting = false,
}) => {
  let baseUrl = url.baseurl ?? baseURL;
  let _header = header;
  if (url.access_token_required) {
    const _access_token = getCurrentAccessToken();
    if (_access_token) {
      _header = {
        ..._header,
        ...{
          Authorization: `Bearer ${_access_token}`,
        },
      };
    }
  }
  let _url = url.route;
  if (pathParams) {
    _url = `${url.route}/${pathParams}`;
  }

  if (queryParams && !_.isEmpty(queryParams)) {
    _url = `${_url}?${
      queryParams instanceof Object ? qs.stringify(queryParams) : queryParams
    }`;
  }

  if (cacheBusting) _url += `?t${Date.now()}`;

  let response = await ApiHandler(url.type, _url, payload, _header, baseUrl);
  return response;
};
