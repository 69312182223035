import { createSlice } from '@reduxjs/toolkit';
// import { CHATS } from "../../constants";

const ChatReducer = createSlice({
  name: 'chat',
  initialState: {
    chats: [],
    messages: []
  },
  reducers: {
    // CREATE CHAT
    createChatRequest() {},
    createChatSuccess(state, { payload }) {
      state.chats = [payload, ...state.chats];
    },
    // CHATS LISTING
    getChatsRequest() {},
    getChatsSuccess(state, { payload }) {
      if (payload.isMoreData) {
        state.chats = [...state.chats, ...payload.chats];
        return;
      }
      state.chats = payload.chats;
    },
    // UPDATE CHAT
    updateChatRequest(state, { payload }) {
      const data = payload.payload;
      const tempdate = state.chats.map((chat) =>
        chat.id === data.details.session_id
          ? { ...chat, name: data.details.session_name }
          : chat
      );
      state.chats = tempdate;
    },
    updateChatSuccess(state, action) {
      state.chats = state.chats.map((chat) =>
        chat.id === action.payload.id ? action.payload : chat
      );
    },
    // DELETE CHAT
    deleteChatRequest() {},
    deleteChatSuccess(state, action) {
      state.chats = state.chats.filter((chat) => chat.id !== action.payload);
    },
    // GET MESSAGES
    getMessagesRequest() {},
    getMessagesSuccess(state, { payload }) {
      // Sometimes we want the data only for our function and not stored.
      if (payload.storeData === false) return;
      if (payload.isMoreData) {
        state.messages = [...payload.messages.messages, ...state.messages];
        return;
      }
      state.messages = payload.messages.messages;
    },
    // SEND MESSAGE
    sendMessageRequest(state, action) {
      const tempdate = [...state.messages, action.payload];
      state.messages = tempdate;
    },
    // RECIEVE MESSAGE
    recieveMessageRequest(state, action) {
      state.messages = [...state.messages, action.payload];
    },

    // REMOVE TEXT ANIMATION
    removeTextAnimation(state, action) {
      state.chats = state.chats.map((chat) =>
        chat.id === action.payload ? { ...chat, isAnimated: false } : chat
      );
    },

    // SEND FEEDBACK
    sendFeedbackRequest(state, action) {
      const payload = action.payload.payload;
      state.messages = state.messages.map((message) =>
        message.id === payload.details.query_id
          ? { ...message, feedback: payload.details.feedback }
          : message
      );
    },

    // DELETE FEEDBACK
    deleteFeedbackRequest(state, action) {
      const payload = action.payload.payload;
      state.messages = state.messages.map((message) =>
        message.id === payload.details.query_id
          ? { ...message, feedback: {} }
          : message
      );
    },

    // CLEAR CHAT
    clearMessages(state) {
      state.messages = [];
    }
  }
});

export const {
  createChatRequest,
  createChatSuccess,
  getChatsRequest,
  getChatsSuccess,
  updateChatRequest,
  updateChatSuccess,
  deleteChatRequest,
  deleteChatSuccess,
  getMessagesRequest,
  getMessagesSuccess,
  sendMessageRequest,
  recieveMessageRequest,
  removeTextAnimation,
  sendFeedbackRequest,
  deleteFeedbackRequest,
  clearMessages
} = ChatReducer.actions;

export default ChatReducer.reducer;
