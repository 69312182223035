import React, { useEffect } from 'react';
import { Loader } from '../../components';
import { getUserDetailsRequest } from '../../redux/slicers/user';
import { getUserAsurite } from '../../utils';
import { CustomDispatch } from '../../helpers';
import ChatWidget from '../../components/chat-widget';
import { getModelsRequest } from '../../redux/slicers/general';

const PrivateSharedLayout = ({ children }) => {
  // CONST VALS
  const userAsurite = getUserAsurite();

  // CUSTOM DISPATCH
  const [getUserDetail, userLoader] = CustomDispatch(
    getUserDetailsRequest,
    true
  );

  const [getModels, modelsLoader] = CustomDispatch(getModelsRequest);

  // HELPERS
  const getUserDetailHelper = () => {
    const payload = {
      resource: 'user',
      method: 'describe_profile',
    };
    getUserDetail({ payload, loggedinUser: true });
  };

  const getModelDataHelper = () => {
    getModels();
  };

  // HOOKS
  useEffect(() => {
    if (!userAsurite) return;
    getUserDetailHelper();
    // getUserAccessHelper();
    getModelDataHelper();
  }, [userAsurite]);

  if (userLoader || modelsLoader) return <Loader />;
  return (
    <>
      {children}
      <ChatWidget />
    </>
  );
};

export default PrivateSharedLayout;
