import { Modal, Button } from "../../../../../components";
import { TOAST_MESSAGES } from "../../../../../constants";
import { CustomDispatch } from "../../../../../helpers";
import { deleteProjectRequest } from "../../../../../redux/slicers/project";
import { toastAlert } from "../../../../../utils";
import "./styles.scss";

const DeleteProject = ({ preview, previewHandler, selectedProject }) => {
  // CUSTOM DISPATCH
  const [deleteProject, isLoading] = CustomDispatch(deleteProjectRequest);

  //HANDLER
  const closeHandler = () => {
    previewHandler(null);
  };
  const deleteHandler = () => {
    const payload = {
      resource: "project",
      method: "delete",
      details: {
        project_id: selectedProject.id,
      },
    };
    deleteProject({
      payload,
      success: () => {
        toastAlert(TOAST_MESSAGES.PROJECT_DELETE_SUCCESS);
        closeHandler();
      },
    });
  };

  if (!selectedProject) return;

  return (
    <Modal
      open={preview}
      openHandler={closeHandler}
      width={682}
      className="delete-project-modal"
    >
      <h2>Are you sure you want to delete this project?</h2>
      <p>
        This will permanently delete this file and any related media you have
        uploaded to this project. Any custom instructions will be lost.
      </p>
      <div className="action-box">
        <Button onClick={closeHandler}>Cancel</Button>
        <Button invertedTheme isLoading={isLoading} onClick={deleteHandler}>
          Delete
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteProject;
