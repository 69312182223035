import { Col, Row } from "antd";
import CreateCard from "./create-card";
import {
  CreateAdvanceProject,
  CreateBasicProject,
  CreateTemplateProject,
} from "../../../../components";
import { useState } from "react";
import Filter from "../../../../components/filter";
import { PROJECT_TEMPLATE_ICONS } from "../../../../constants";

const SHOW_ALL = "Show all";
const FAVORITES = "Favorites";

const options = [
  { name: SHOW_ALL, icon: "" },
  ...Object.entries(PROJECT_TEMPLATE_ICONS).map((entry) => ({
    name: entry[0],
    icon: entry[1],
  })),
];

const StaticCardListing = ({ data }) => {
  const [basicProjectPreview, setBasicProjectPreview] = useState(false);
  const [templateProjectPreview, settemplateProjectPreview] = useState(false);
  const [advancedProjectPreview, setAdvancedProjectPreview] = useState(false);

  const [filteredData, setFilteredData] = useState(data);
  const [selected, setSelected] = useState(options[0].name);

  // HANDLERS
  const basicProjectPreviewHandler = () => {
    setBasicProjectPreview(!basicProjectPreview);
  };

  const templateProjectPreviewHandler = () => {
    settemplateProjectPreview(!templateProjectPreview);
  };

  const advancedProjectPreviewHandler = () => {
    setAdvancedProjectPreview(!advancedProjectPreview);
  };

  function onKeyChange(key) {
    setSelected(key);
    if (key === SHOW_ALL) setFilteredData(data);
    else if (key === FAVORITES)
      setFilteredData(data.filter((obj) => obj.favorite));
    else setFilteredData(data.filter((obj) => obj.type === key));
  }

  return (
    <div>
      <Filter onClick={onKeyChange} options={options} selected={selected} />
      <div className="cards-listing">
        <Row gutter={[16, 24]}>
          {filteredData.map((card) => (
            //flex="20%"
            <Col xs={24} md={12} lg={8} xl={6} key={card.title}>
              <CreateCard onClick={advancedProjectPreviewHandler} card={card} />
            </Col>
          ))}
        </Row>
      </div>

      <CreateBasicProject
        preview={basicProjectPreview}
        previewHandler={basicProjectPreviewHandler}
      />
      <CreateTemplateProject
        preview={templateProjectPreview}
        previewHandler={templateProjectPreviewHandler}
      />
      <CreateAdvanceProject
        preview={advancedProjectPreview}
        previewHandler={advancedProjectPreviewHandler}
      />
    </div>
  );
};

export default StaticCardListing;
