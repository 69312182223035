import React, { useState, useEffect } from "react";
import { Tooltip } from "antd";
import { Button, Table, ConfirmationModal } from "../../../../components";
import { AddUser } from "./";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomDispatch } from "../../../../helpers";
import { removeUserFromGroupRequest } from "../../../../redux/slicers/groups";

const UserTable = ({ data = [], isGroupAdmin, getGroupDetail }) => {
  // STATES
  const [filteredData, setfilteredData] = useState([]);
  const [addUserPreview, setAddUserPreview] = useState(false);
  const [removeUserPreview, setRemoveUserPreview] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  // CUSTOM DISPATCH
  const [removeUser, removeLoader] = CustomDispatch(removeUserFromGroupRequest);

  // HANDLERS
  const searchHandler = (e) => {
    const { value } = e.target;
    if (value === "") return setfilteredData(data);
    const filtered = data.filter((item) =>
      item.id.toLowerCase().includes(value.toLowerCase())
    );
    setfilteredData(filtered);
  };
  const addUserPreviewHandler = () => {
    setAddUserPreview(!addUserPreview);
    setSelectedData(null);
  };
  const editUserPreviewHandler = (data) => {
    setAddUserPreview(true);
    setSelectedData(data);
  };
  const removeUserPreviewHandler = (data) => {
    setRemoveUserPreview(true);
    setSelectedData(data);
  };
  const removeUserHandler = () => {
    const payload = {
      resource: "access",
      method: "remove",
      details: {
        users: [
          {
            user_id: selectedData.id,
            role: selectedData.role,
          },
        ],
        group_id: selectedData.group,
      },
    };
    removeUser({
      payload,
      success() {
        setRemoveUserPreview(false);
      },
    });
  };

  // CONST VALS
  const tableColumns = [
    {
      title: "Asurite ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    // {
    //   title: "Phone",
    //   dataIndex: "phone",
    // },
    {
      title: "Role",
      dataIndex: "roleLabel",
    },
    {
      title: "",
      dataIndex: "",
      render: (data) => {
        return (
          <span className="actions-box">
            <Tooltip title="Edit user role">
              <FontAwesomeIcon
                onClick={() => editUserPreviewHandler(data)}
                icon={faEdit}
              />
            </Tooltip>
            <Tooltip title="Remove user">
              <FontAwesomeIcon
                onClick={() => removeUserPreviewHandler(data)}
                className="delete"
                icon={faTrashAlt}
              />
            </Tooltip>
          </span>
        );
      },
    },
  ];

  // HOOKS
  useEffect(() => {
    setfilteredData(data);
  }, [data]);

  return (
    <>
      <Table
        title="Users"
        bordered
        isSearch
        columns={tableColumns}
        data={filteredData}
        searchHandler={searchHandler}>
        {!!isGroupAdmin && <Button onClick={addUserPreviewHandler}>Add user</Button>}
      </Table>
      <AddUser
        isEdit={selectedData}
        data={selectedData}
        preview={addUserPreview}
        previewHandler={addUserPreviewHandler}
        successHandler={getGroupDetail}
      />
      <ConfirmationModal
        title="Remove User"
        description="Are you sure you want to remove this user from group?"
        isLoading={removeLoader}
        preview={removeUserPreview}
        previewHandler={() => setRemoveUserPreview(false)}
        confirmBtnHandler={removeUserHandler}
      />
    </>
  );
};

export default UserTable;
