import { useState } from "react";
import { Form, Input } from "antd";
import { Modal, Button } from "../../../../../components";
import { inputFieldRule, toastAlert } from "../../../../../utils";
import { CustomDispatch } from "../../../../../helpers";
import {
  createProjectRequest,
  getProjectDetailRequest,
} from "../../../../../redux/slicers/project";
import { manipulateCloneProjectData } from "../../../../../data-manipulator/project";
import { PROJECT_ROUTE, TOAST_MESSAGES } from "../../../../../constants";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

const CopyProject = ({ preview, previewHandler, selectedProject }) => {
  // STATES
  const [isLoading, setLoading] = useState(false);

  // CONST VALS
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // CUSTOM DISPATCH
  const [getProject] = CustomDispatch(getProjectDetailRequest);
  const [copyProject] = CustomDispatch(createProjectRequest);

  // HELPERS
  const copyProjectHelper = (projectdata, values) => {
    const payload = {
      resource: "project",
      method: "create",
      details: manipulateCloneProjectData({ ...projectdata, ...values }),
    };
    copyProject({
      payload,
      success: (data) => {
        toastAlert(TOAST_MESSAGES.PROJECT_COPY_SUCCESS);
        closeHandler();
        navigate(PROJECT_ROUTE.replace(":projectslug", data.id));
      },
    });
  };

  const getProjectDetailHelper = (values) => {
    const payload = {
      resource: "project",
      method: "describe",
      details: {
        project_id: selectedProject.id,
      },
    };
    getProject({
      payload,
      success: (data) => copyProjectHelper(data, values),
      error: () => setLoading(false),
    });
  };

  //HANDLER
  const closeHandler = () => {
    previewHandler(null);
  };
  const handleSubmit = (values) => {
    setLoading(true);
    getProjectDetailHelper(values);
  };

  if (!selectedProject) return;

  return (
    <Modal
      open={preview}
      openHandler={closeHandler}
      width={682}
      className="copy-project-modal"
    >
      <div className="intro-box">
        <h2>Name Your Copy</h2>
        <p>
          Please provide a title for your project. Default settings, including
          models, custom instructions, and project sharing, have been applied
          based on the selected use case. You can adjust these settings later in
          the project properties panel on the right.
        </p>
      </div>
      <Form form={form} className="customize-form" onFinish={handleSubmit}>
        <label htmlFor="projectName">Project name</label>
        <Form.Item
          name="projectName"
          rules={inputFieldRule({
            name: "Project name",
            isMax: true,
            max: 120,
          })}
        >
          <Input id="projectName" />
        </Form.Item>

        {/* <Form.Item name="clone">
          <Checkbox>Clone sharing permissions</Checkbox>
        </Form.Item>
        <Form.Item name="otherOptions">
          <Checkbox>Another options to keep</Checkbox>
        </Form.Item> */}

        <div className="action-box">
          <Button onClick={closeHandler}>Cancel</Button>
          <Button invertedTheme isLoading={isLoading}>
            Submit
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CopyProject;
