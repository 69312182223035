import ErrorThumbnail from "../assets/images/error-thumb.svg";
import Logo from "../assets/images/logo.png";
import LogoThumb from "../assets/images/logo-thumb.png";
import UserThumb from "../assets/images/user.jpeg";
import ASUBanner from "../assets/images/asu-banner.png";
import ASUPlaceholder from "../assets/images/asu-placeholder-img.png";
import AI from "../assets/images/ai.svg";
import UpDownLeftRight from "../assets/images/up-down-left-right.svg";
import Stars from "../assets/images/stars.svg";
import VoiceLoader from "../assets/images/voice-loader.gif";
import ModelComparison from "../assets/images/model-comparison.svg";
import ASUGpt from "../assets/images/asu-gpt.svg";
import ThankyouThumb from "../assets/images/thankyou-thumb.png";

const Images = {
  ErrorThumbnail,
  Logo,
  LogoThumb,
  UserThumb,
  ASUBanner,
  ASUPlaceholder,
  AI,
  UpDownLeftRight,
  Stars,
  VoiceLoader,
  ModelComparison,
  ASUGpt,
  ThankyouThumb,
};

export default Images;
