import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faTimesCircle,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Form, Input, Tooltip } from "antd";
import { inputFieldRule } from "../../../../../../../utils";
import LabelBox from "../../label-box";
import converter from "number-to-words";

const ConversationStarters = () => {
  return (
    <div className="conversation-starters">
      <Form.List name="starterGroups">
        {(fields, { add, remove }) => (
          <>
            <div className="suffix-fields title">
              <LabelBox
                title="Conversation starters"
                message="Create engaging prompts to guide users in interacting with your project."
              />
              {fields.length < 3 && (
                <Tooltip title="Add conversation starter group">
                  <button
                    type="button"
                    className="suffix-btn"
                    onClick={() => add()}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </Tooltip>
              )}
            </div>
            {fields.map((field) => (
              <div key={field.key} className="group-box">
                <div className="suffix-fields title">
                  <LabelBox
                    heading
                    title={
                      <>Group {converter.toWords(field.name + 1)} details</>
                    }
                  />
                  <Tooltip title="Remove conversation starter group">
                    <button type="button" className="suffix-btn delete-group">
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </button>
                  </Tooltip>
                </div>
                <Form.Item
                  name={[field.name, "title"]}
                  rules={inputFieldRule({
                    name: "Column title",
                    isMax: true,
                    max: 150,
                  })}
                >
                  <Input placeholder="Column Title" />
                </Form.Item>
                <Form.Item>
                  <Form.List
                    name={[field.name, "starters"]}
                    initialValue={["", ""]}
                  >
                    {(subFields, subOpt) => (
                      <>
                        <div className="group-questions">
                          {subFields.map((subField) => (
                            <div key={subField.key}>
                              <Form.Item
                                name={subField.name}
                                rules={inputFieldRule({
                                  name: "Starter",
                                  isMax: true,
                                  max: 150,
                                })}
                              >
                                <Input />
                              </Form.Item>
                              {subFields.length > 1 && (
                                <FontAwesomeIcon
                                  className="remove-btn"
                                  icon={faTimesCircle}
                                  onClick={() => {
                                    subOpt.remove(subField.name);
                                  }}
                                />
                              )}
                            </div>
                          ))}
                        </div>
                        <button
                          type="button"
                          className="add-question"
                          onClick={() => subOpt.add()}
                        >
                          + Add Conversation Starter
                        </button>
                      </>
                    )}
                  </Form.List>
                </Form.Item>
              </div>
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
};

export default ConversationStarters;
