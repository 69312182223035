import React from "react";
import { Button, Modal } from "../";
import { Images } from "../../theme";
import "./styles.scss";

const ThankyouModal = ({
  preview = false,
  previewHandler = () => {},
  feedbackHandler = () => {},
}) => {
  return (
    <Modal
      width={580}
      openHandler={previewHandler}
      open={preview}
      className="thankyou-modal"
      closable={false}
    >
      <div className="top-box">
        <div className="thumb">
          <img src={Images.ThankyouThumb} alt="" />
        </div>
        <div className="details">
          <h2>Thank you</h2>
          <p>Your feedback is important to us!</p>
        </div>
      </div>
      <div className="action-box">
        <button className="feedback-btn" onClick={feedbackHandler}>
          Provide more feedback
        </button>
        <Button boldText invertedTheme onClick={previewHandler}>
          Done
        </Button>
      </div>
    </Modal>
  );
};

export default ThankyouModal;
