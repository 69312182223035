import React from "react";
import { Table as AntdTable } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./styles.scss";
import { Icons } from "../../theme";
import Loader from "../loader";

const Table = ({
  title = "",
  bordered,
  isSearch,
  searchPlaceholder = "Search here...",
  searchHandler = () => {},
  className = "",
  borderedTable,
  columns,
  isLoading,
  height = "400px",
  data = [],
  children,
  onRow,
}) => {
  // CONST VALS
  const topBoxPreview = title || isSearch || children;
  const filteredData = data?.map((item, index) => {
    return { ...item, key: index + 1 };
  });

  // CUSTOM COMPONENT
  const noDataBox = () => (
    <div className="no-data-wrapper">
      {isLoading ? (
        <Loader height="110px" size={14} />
      ) : (
        <>
          <img src={Icons.NoMedia} alt="" />
          <h4>No Data Found</h4>
        </>
      )}
    </div>
  );
  return (
    <div className={`table-wrapper ${className} ${bordered ? "bordered" : ""}`}>
      {!!topBoxPreview && (
        <div className="top-box">
          <h4>{title}</h4>
          {!!isSearch && (
            <div className="search-box">
              <FontAwesomeIcon className="thumb" icon={faSearch} />
              <input onChange={searchHandler} placeholder={searchPlaceholder} />
            </div>
          )}
          {!!children && <div className="action-box">{children}</div>}
        </div>
      )}
      <div className="table-box">
        <AntdTable
          {...(onRow ? { onRow } : {})}
          bordered={borderedTable}
          // loading={{ indicator: loadingBox(), spinning: isLoading }}
          columns={columns}
          dataSource={isLoading ? [] : filteredData}
          pagination={false}
          locale={{
            emptyText: noDataBox(),
          }}
          scroll={{
            y: height,
            x: "max-content",
          }}
        />
      </div>
    </div>
  );
};

export default Table;
