import { Button } from "../../../../components";
// import { APIDOC_ROUTE } from "../../../../constants";

const Banner = ({ imgSrc, title, description, btnText }) => {
  return (
    <div className="banner-wrapper">
      <div className="image-box">
        <img src={imgSrc} alt="ASU Banner" />
      </div>
      <div className="detail">
        <h2>{title}</h2>
        <p>{description}</p>
        <Button
          isLink
          link="https://docs.google.com/document/d/1IFRJKII6zP-3Pw6Nikq_AgRUXBEmIp9rqoA_fQhaGgk/edit?usp=sharing"
          target="_blank"
          boldText
          className="cta-btn"
          invertedTheme
          type="pill"
        >
          {btnText}
        </Button>
      </div>
    </div>
  );
};

export default Banner;
