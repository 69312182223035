import { call, fork, put, take } from "redux-saga/effects";
import { callRequest, GET_USER_DETAIL } from "../../config/web-service";
import { ALERT_TYPES } from "../../constants";
import { toastAlert } from "../../utils";
import { getUserDetailsRequest, getUserDetailsSuccess } from "../slicers/user";

// GET LOGGED IN USER DETAIL
function* getUserDetail() {
  while (true) {
    const {
      payload: { payload, loggedinUser, responseCallback },
    } = yield take(getUserDetailsRequest.type);
    try {
      const response = yield call(callRequest, {
        url: GET_USER_DETAIL,
        payload,
      });
      responseCallback?.(response?.status, response.data.users);
      !response.status && toastAlert(response.message, ALERT_TYPES.ERROR);
      if (response.status && loggedinUser)
        yield put(getUserDetailsSuccess(response.data.user));
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

export default function* root() {
  yield fork(getUserDetail);
}
