import React, { useMemo, useState } from "react";
import {
  Projects,
  // Resources
} from "./components";
import "./styles.scss";
import Banner from "./components/banner";
import { Images } from "../../../theme";
import { CreateAdvanceProject } from "../../../components";

const Dashboard = () => {
  // STATES
  const [createProjectPreview, setCreateProjectPreview] = useState(false);

  // HANDLERS
  const createProjectHandler = () => {
    setCreateProjectPreview(!createProjectPreview);
  };

  // CUSTOM COMPONENT
  const projectsComp = useMemo(() => {
    return <Projects />;
  }, []);

  return (
    <div className="dashboard">
      <div className="create-project-action-box">
        <button onClick={createProjectHandler}>Create new project</button>
      </div>
      <Banner
        imgSrc={Images.ASUBanner}
        title="Welcome To MyAI Builder"
        description="MyAI Builder is an AI product built on the CreateAI Platform to help anyone at ASU create, collaborate on, and serve custom AI experiences."
        btnText="Learn how to use MyAI Builder"
      />
      {projectsComp}
      <CreateAdvanceProject
        preview={createProjectPreview}
        previewHandler={createProjectHandler}
      />
    </div>
  );
};

export default Dashboard;
