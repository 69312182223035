import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PROJECT_ROUTE } from '../../../../constants';
import {
  faCommentAlt,
  faEdit,
  faTrashAlt,
  faFileLines,
  faCopy,
  faCircleCheck
} from '@fortawesome/free-regular-svg-icons';
import {
  deleteChatRequest,
  getChatsRequest,
  removeTextAnimation,
  updateChatRequest,
  getMessagesRequest
} from '../../../../redux/slicers/chat';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { CustomDispatch } from '../../../../helpers';
import { ConfirmationModal, Loader } from '../../../../components';
import { makeTextToShare } from '../../../../utils';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReactTyped } from 'react-typed';
import { Dropdown, Input } from 'antd';
import ShareAllIcon from '../../../../components/icons/ShareAllIcon';
import {
  faChevronLeft,
  faChevronRight,
  faComments,
  faEllipsis,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';
import './styles.scss';

const ChatSidebar = ({ preview, previewHandler }) => {
  // STATES
  const [moreChatsData, setmoreChatsData] = useState(null);
  const [deleteChatPreview, setDeleteChatPreview] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const [isAllCopied, setIsAllCopied] = useState({});
  const [isAllShared, setIsAllShared] = useState({});
  const [allTextToShare, setAllTextToShare] = useState({});

  // CUSTOM DISPATCH
  const [getChats, chatLoader] = CustomDispatch(getChatsRequest);
  const [getMessages] = CustomDispatch(getMessagesRequest);
  const [deleteChat, deleteLoader] = CustomDispatch(deleteChatRequest);
  const [updateChat] = CustomDispatch(updateChatRequest);

  // REDUX DATA
  const chats = useSelector(({ chat }) => chat.chats);

  // CONST VALS
  const navigate = useNavigate();
  const { projectslug: projectId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentChat = searchParams.get('chat');
  const chatCategories = [...new Set(chats.map((chat) => chat.category))];
  const dispatch = useDispatch();
  const editInputRef = useRef(null);

  // HELPERS
  const getChatsHelper = () => {
    const payload = {
      method: 'list',
      details: {
        app_id: projectId,
        archive: false
      },
      item_limit: 50
    };
    if (moreChatsData) payload['next_page'] = moreChatsData;
    getChats({
      payload,
      success: (data) => setmoreChatsData(data?.next_page)
    });
  };
  const getMessagesHelper = (chatId) => {
    const payload = {
      method: 'describe',
      details: {
        app_id: projectId,
        session_id: chatId
      },
      item_limit: 50,
      storeData: false
    };
    getMessages({
      payload,
      success: (data) => {
        let textToShare = '';
        if (Array.isArray(data?.chat_session?.chat_history)) {
          textToShare = data?.chat_session?.chat_history
            .map((message, index) => {
              return makeTextToShare(message.query, message.response);
            })
            .join('\n\n');
        } else {
          textToShare = '';
        }
        setAllTextToShare((current) => ({
          ...current,
          [chatId]: textToShare
        }));
      },
      error: () => {
        setIsAllCopied({});
        setIsAllShared({});
        setSearchParams({});
      }
    });
  };

  // HANDLERS
  const handleScroll = (e) => {
    if (e.target.scrollTop + e.target.clientHeight !== e.target.scrollHeight)
      return;
    if (moreChatsData && !chatLoader) getChatsHelper();
  };

  const removeAnimationHandler = (id) => {
    dispatch(removeTextAnimation(id));
  };

  const editChatPreviewHandler = (e, index, item) => {
    e.stopPropagation();
    setSelectedChat(item.id);
    setEditInputValue(item.name);
    setEditInputIndex(item.id);
  };

  const updateChatHandler = () => {
    const oldVal = chats.find((x) => x.id === editInputIndex).name;
    setEditInputIndex(-1);
    if (editInputValue === oldVal || editInputValue === '') return;
    const payload = {
      method: 'update',
      details: {
        app_id: projectId,
        session_id: selectedChat,
        session_name: editInputValue
      }
    };
    updateChat({ payload });
    setEditInputValue('');
    setSelectedChat(null);
  };

  const deleteChatPreviewHandler = (e, id) => {
    e.stopPropagation();
    setSelectedChat(id);
    setDeleteChatPreview(true);
  };

  const deleteChatHandler = () => {
    const payload = {
      method: 'delete',
      details: {
        app_id: projectId,
        session_id: selectedChat
      }
    };
    deleteChat({
      payload,
      success() {
        setDeleteChatPreview(false);
        if (currentChat !== selectedChat) return;
        const projectRoute = PROJECT_ROUTE.replace(':projectslug', projectId);
        navigate(projectRoute);
      }
    });
  };

  const shareText = async (title, text) => {
    setIsAllShared({ chatId: isAllShared.chatId, loading: false });
    const messageBoxTitle = 'messageBoxTitle';
    try {
      if (navigator?.share) {
        const sharedData = { title, text };
        await navigator.share(sharedData);
      } else {
        toastAlert('Your browser does not support sharing.', ALERT_TYPES.ERROR);
      }
    } catch (e) {
      console.error(`Error while sharing.`, e);
    } finally {
      setTimeout(() => {
        setIsAllShared({});
      }, 2000);
    }
  };

  const copyText = async (title, text) => {
    setIsAllCopied({ chatId: isAllCopied.chatId, loading: false });
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      console.error(`Error while copying.`, error);
    } finally {
      setTimeout(() => {
        setIsAllCopied({});
      }, 2000);
    }
  };

  const handleShareAll = async (chatId) => {
    setIsAllShared({ chatId, loading: true });
    if (!allTextToShare || !allTextToShare[chatId]) {
      getMessagesHelper(chatId);
    }
  };

  const handleCopyAll = (chatId) => {
    setIsAllCopied({ chatId, loading: true });
    if (!allTextToShare || !allTextToShare[chatId]) {
      getMessagesHelper(chatId);
    }
  };

  useEffect(() => {
    getChatsHelper();
  }, []);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputIndex]);

  useEffect(() => {
    if (isAllShared?.chatId && allTextToShare[isAllShared?.chatId]) {
      shareText('', allTextToShare[isAllShared.chatId]);
    }
    if (isAllCopied?.chatId && allTextToShare[isAllCopied?.chatId]) {
      copyText('', allTextToShare[isAllCopied.chatId]);
    }
  }, [isAllShared, isAllCopied, allTextToShare]);

  return (
    <nav
      role="navigation"
      className={clsx('chat-sidebar', preview && 'active')}
    >
      <button className="sidebar-toggle" onClick={previewHandler}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
      {chatLoader && !moreChatsData ? (
        <Loader height="100%" size={12} />
      ) : chats.length >= 1 ? (
        <div className="chats-listing customize-form" onScroll={handleScroll}>
          {chatCategories.map((chat, index) => {
            const catChats = chats?.filter((item) => item.category === chat);
            return (
              <React.Fragment key={index}>
                <button className="chat-title">{chat}</button>
                {catChats.map((item, i) => {
                  const itemIndex = `${index}-${i}`;
                  const isEdit = editInputIndex === item.id;
                  const shareDataLoading =
                    (isAllCopied?.chatId === item.id && isAllCopied?.loading) ||
                    (isAllShared?.chatId === item.id && isAllShared?.loading);
                  const shareDataComplete =
                    (isAllCopied?.chatId === item.id &&
                      !isAllCopied?.loading) ||
                    (isAllShared?.chatId === item.id && !isAllShared?.loading);

                  const menuItems = [
                    {
                      key: '1',
                      label: (
                        <div
                          className="menu-button"
                          role="button"
                          onClick={(e) =>
                            editChatPreviewHandler(e, itemIndex, item)
                          }
                          onKeyPress={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              editChatPreviewHandler(e, itemIndex, item);
                            }
                          }}
                          tabIndex={0}
                        >
                          Edit
                        </div>
                      ),
                      icon: (
                        <FontAwesomeIcon
                          onClick={(e) =>
                            editChatPreviewHandler(e, itemIndex, item)
                          }
                          icon={faEdit}
                        />
                      ),
                      disabled: isEdit
                    },
                    {
                      key: '5',
                      label: (
                        <div
                          className="menu-button"
                          role="button"
                          onClick={(e) => handleCopyAll(item.id)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              handleCopyAll(item.id);
                            }
                          }}
                          tabIndex={0}
                        >
                          Copy Chat
                        </div>
                      ),
                      icon: (
                        <FontAwesomeIcon
                          icon={faFileLines}
                          onCopy={() => handleCopyAll(item.id)}
                        />
                      ),
                      disabled: shareDataLoading
                    },
                    {
                      key: '6',
                      label: (
                        <div
                          className="menu-button"
                          role="button"
                          onClick={(e) => handleShareAll(item.id)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              handleShareAll(item.id);
                            }
                          }}
                          tabIndex={0}
                        >
                          Share Chat
                        </div>
                      ),
                      icon: (
                        <ShareAllIcon
                          className="shareall-icon"
                          onClick={(e) => handleShareAll(item.id)}
                        />
                      ),
                      disabled: shareDataLoading
                    },
                    {
                      key: '2',
                      label: (
                        <div
                          className="menu-button"
                          role="button"
                          onClick={(e) => deleteChatPreviewHandler(e, item.id)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              deleteChatPreviewHandler(e, item.id);
                            }
                          }}
                          tabIndex={0}
                        >
                          Delete
                        </div>
                      ),
                      icon: (
                        <FontAwesomeIcon
                          onClick={(e) => deleteChatPreviewHandler(e, item.id)}
                          icon={faTrashAlt}
                        />
                      ),
                      disabled: isEdit,
                      danger: true
                    }
                  ];
                  return (
                    <button
                      key={itemIndex}
                      onClick={() =>
                        !isEdit && setSearchParams({ chat: item.id })
                      }
                      className={clsx(
                        `chat-item`,
                        currentChat === item.id && 'active'
                      )}
                    >
                      <FontAwesomeIcon className="thumb" icon={faCommentAlt} />
                      <span className="title">
                        {isEdit ? (
                          <Input
                            ref={editInputRef}
                            key={itemIndex}
                            size="small"
                            value={editInputValue}
                            onChange={(e) => setEditInputValue(e.target.value)}
                            onBlur={updateChatHandler}
                            onPressEnter={updateChatHandler}
                          />
                        ) : (
                          <>
                            {item.isAnimated ? (
                              <ReactTyped
                                strings={[item.name]}
                                typeSpeed={25}
                                onComplete={() =>
                                  removeAnimationHandler(item.id)
                                }
                                showCursor={false}
                              />
                            ) : (
                              item.name
                            )}
                          </>
                        )}
                      </span>
                      {shareDataLoading || shareDataComplete ? (
                        <FontAwesomeIcon
                          icon={shareDataLoading ? faSpinner : faCircleCheck}
                          spin={shareDataLoading}
                          className="spinner-btn"
                        />
                      ) : (
                        <div className="action-box">
                          <Dropdown
                            menu={{
                              items: menuItems,
                              onClick: (e) => e.domEvent.stopPropagation()
                            }}
                            trigger="click"
                          >
                            <FontAwesomeIcon
                              onClick={(e) => e.stopPropagation()}
                              className="ellipsis-btn"
                              icon={faEllipsis}
                            />
                          </Dropdown>
                        </div>
                      )}
                    </button>
                  );
                })}
              </React.Fragment>
            );
          })}
          {!!moreChatsData && !!chatLoader && (
            <Loader height="30px" size={10} style={{ marginTop: '10px' }} />
          )}
        </div>
      ) : (
        <div className="empty-box">
          <FontAwesomeIcon className="thumb" icon={faComments} />
          <h4>No conversations yet</h4>
        </div>
      )}
      <ConfirmationModal
        preview={deleteChatPreview}
        isLoading={deleteLoader}
        title="Delete Chat"
        description="Are you sure you want to delete this chat?"
        confirmBtnText="Delete"
        confirmBtnHandler={deleteChatHandler}
        previewHandler={() => setDeleteChatPreview(false)}
      />
    </nav>
  );
};

export default ChatSidebar;
