import React, { useEffect } from 'react';
import { CustomDispatch } from '../../../../helpers';
import { getProjectsRequest } from '../../../../redux/slicers/project';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  PROJECT_ROUTE,
  PROJECT_ROLES,
  // CREATE_PROJECT_DATA,
  PROJECT_SECTIONS
} from '../../../../constants';
import ProjectSection from './project-section';

const Projects = () => {
  // REDUX DATA
  const projects = useSelector(({ project }) => project.projects);

  // CONST VALS
  const navigate = useNavigate();

  // CUSTOM DISPATCH
  const [getProjects, isLoading] = CustomDispatch(getProjectsRequest);

  const navigateToProject = (id, isSharedProject) => {
    if (!isSharedProject) navigate(PROJECT_ROUTE.replace(':projectslug', id));
    else window.open(id, '_blank');
  };

  // HOOKS
  useEffect(() => {
    const payload = {
      resource: 'project',
      method: 'list'
    };
    getProjects({ payload });
  }, []);

  return (
    <div className="projects-wrapper">
      <div className="recent-projects project-section">
        <ProjectSection
          section={PROJECT_SECTIONS.RECENT}
          title="Your recent projects"
          desc="Access projects where you're an owner."
          projectRoles={[PROJECT_ROLES.OWNER]}
          isLoading={isLoading}
          projects={projects}
          navigateToProject={navigateToProject}
        />
      </div>

      {/* DO NOT DELETE: To be uncommented later */}
      {/* <div className="seperator"></div>

      <div className="create-projects project-section">
        <ProjectSection
          section={PROJECT_SECTIONS.CREATE}
          title="Create a project"
          desc="To start a new project, select a use case from the options provided. " +
            "Each use case is pre-configured with the recommended settings to help you quickly build an AI" + 
            " applications tailored to your goals." +
            " You can further customize these settings after selection to fit your specific needs."
          projectRoles={[]}
          isLoading={isLoading}
          projects={CREATE_PROJECT_DATA}
          navigateToProject={navigateToProject}
        />
      </div> */}

      <div className="seperator" />

      <div className="shared-projects project-section">
        <ProjectSection
          section={PROJECT_SECTIONS.SHARED}
          title="Shared projects"
          desc="Access projects where you're added as an editor or a viewer."
          projectRoles={[PROJECT_ROLES.VIEWER, PROJECT_ROLES.EDITOR]}
          isLoading={isLoading}
          projects={projects}
          navigateToProject={navigateToProject}
        />
      </div>
    </div>
  );
};

export default Projects;
