import React from "react";
import { Form, Input } from "antd";
import { LabelBox } from "../../";
import { inputFieldRule } from "../../../../../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
// import { Button } from "../../../../../../../components";
// import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import ConversationStarters from "./conversation-starters";
import { useDispatch, useSelector } from "react-redux";
import {
  setFieldsValues,
  setFieldsVisibility,
} from "../../../../../../../redux/slicers/project";

const UiBuilder = () => {
  // REDUX DATA
  const data = useSelector(({ project }) => project.selectedProject);
  const fieldsView = useSelector(({ project }) => project.fieldsVisibility);

  // CONST VALS
  const dispatch = useDispatch();

  // HANDLERS
  const handleFieldVisibility = (field) => {
    dispatch(setFieldsVisibility(field));
  };
  const handleFieldValue = (field, value) => {
    dispatch(setFieldsValues({ field, value }));
  };
  return (
    <>
      <p className="sub-description">Customize your project&apos;s interface.</p>
      <LabelBox
        name="webTitle"
        title="Title"
        message="The project title will default to your project name, but you can override it here."
      />
      <div className="suffix-fields">
        <Form.Item
          name="webTitle"
          rules={inputFieldRule({
            isRequired: false,
            requiredMessage: "Invalid title",
            name: "Title",
            isMax: true,
            max: 120,
          })}
        >
          <Input
            id="webTitle"
            onChange={({ target }) => handleFieldValue("title", target.value)}
          />
        </Form.Item>
        <button
          type="button"
          className="suffix-btn"
          onClick={() => handleFieldVisibility("title")}
        >
          <FontAwesomeIcon icon={fieldsView.title ? faEye : faEyeSlash} />
        </button>
      </div>
      {/* SUB TITLE */}
      <LabelBox
        name="subtitle"
        title="Sub-title"
        message="Add a sub-title to provide additional context or information for your project."
      />
      <div className="suffix-fields">
        <Form.Item
          name="publicDescription"
          rules={inputFieldRule({
            isRequired: false,
            requiredMessage: "Invalid subtitle",
            name: "Subtitle",
            isMax: true,
            max: 120,
          })}
        >
          <Input.TextArea
            rows={2}
            id="subtitle"
            onChange={({ target }) =>
              handleFieldValue("subtitle", target.value)
            }
          />
        </Form.Item>
        <button
          type="button"
          className="suffix-btn"
          onClick={() => handleFieldVisibility("subtitle")}
        >
          <FontAwesomeIcon icon={fieldsView.subtitle ? faEye : faEyeSlash} />
        </button>
      </div>
      {/* PROJECT ICON */}
      {/* <LabelBox heading title="Project Icon" />
      <div className="icon-uploader">
        <Image src="" alt="" fallback={DEFAULT_PROJECT_THUMB} preview={false} />
        <div className="action-btns">
          <Button type="button" invertedTheme>
            Upload Image
          </Button>
          <Button type="button" className="regenerate-key">
            <FontAwesomeIcon icon={faRefresh} /> Generate Image
          </Button>
        </div>
      </div> */}
      <ConversationStarters />
      {/* BOT NAME */}
      <LabelBox
        title="Bot Name"
        name="botName"
        message="Choose a name for model's responses"
      />
      <Form.Item
        name="botName"
        rules={inputFieldRule({
          isRequired: false,
          name: "Bot name",
          isMax: true,
          max: 120,
        })}
      >
        <Input id="botName" />
      </Form.Item>
      {/* INPUT PLACEHOLDER */}
      <LabelBox
        title="Prompt box text"
        name="inputBoxPlaceholder"
        message="Change the input box text."
      />
      <div className="suffix-fields">
        <Form.Item
          name="inputBoxPlaceholder"
          rules={inputFieldRule({
            isRequired: false,
            name: "Input placeholder",
            isMax: true,
            max: 120,
          })}
        >
          <Input
            id="inputBoxPlaceholder"
            placeholder="Message preview…"
            onChange={({ target }) => handleFieldValue("input", target.value)}
          />
        </Form.Item>
        <button
          type="button"
          className="suffix-btn"
          onClick={() => handleFieldVisibility("input")}
        >
          <FontAwesomeIcon icon={fieldsView.input ? faEye : faEyeSlash} />
        </button>
      </div>
      {/* DISCLAIMER MESSAGE */}
      <LabelBox
        title="Disclaimer message"
        name="desclaimerMessage"
        message="Set clear expectations by customizing the chatbot's disclaimer about its capabilities and limitations."
      />
      <div className="suffix-fields">
        <Form.Item
          name="desclaimerMessage"
          rules={inputFieldRule({
            isRequired: false,
            name: "Discalimer message",
            isMax: true,
            max: 120,
          })}
        >
          <Input.TextArea
            rows={2}
            id="desclaimerMessage"
            placeholder={`${
              data.webTitle ?? data.projectName
            } bot may display incorrect or false information.`}
            onChange={({ target }) =>
              handleFieldValue("desclaimer", target.value)
            }
          />
        </Form.Item>
        <button
          type="button"
          className="suffix-btn"
          onClick={() => handleFieldVisibility("desclaimer")}
        >
          <FontAwesomeIcon icon={fieldsView.desclaimer ? faEye : faEyeSlash} />
        </button>
      </div>
    </>
  );
};

export default UiBuilder;
