import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Avatar, Image } from "antd";
import { Images } from "../../../../theme";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faPenRuler } from "@fortawesome/free-solid-svg-icons";
import { DASHBOARD_ROUTE } from "../../../../constants";
import clsx from "clsx";
import { Dropdown } from "../../../../components";
import { ReportProblem, Feedback } from "../";
import "./styles.scss";

const Option = ({ icon, label }) => {
  return (
    <div className="dropdown-option">
      <FontAwesomeIcon icon={icon} />
      <p>{label}</p>
    </div>
  );
};

const Header = ({
  sidebarPreview,
  setSidebarPreview,
  createProjectHandler,
}) => {
  // STATES
  const [profileOptionPreview, setProfileOptionPreview] = useState(false);
  // const [helpDropdownPreview, setHelpDropdownPreview] = useState(false);
  const [feedbackPreview, setFeedbackPreview] = useState(false);
  const [reportPreview, setReportPreview] = useState(false);

  // CONST VALS
  const createDropdownOptions = [
    {
      label: "Project",
      value: "project",
      icon: faPenRuler,
      onClick: createProjectHandler,
    },
  ];

  // REDUX DATA
  const { data: userdata } = useSelector(({ user }) => user);

  // HANDLERS
  const setprofileOptionPreviewHandler = () => {
    setProfileOptionPreview(!profileOptionPreview);
  };

  const setFeedbackPreviewHandler = () => {
    setFeedbackPreview(!feedbackPreview);
  };

  const setReportPreviewHandler = () => {
    setReportPreview(!reportPreview);
  };

  // HOOKS
  useEffect(() => {
    // function to close profile options preview on outside click
    document.addEventListener("click", (e) => {
      if (
        !e.target.closest(".profile-box") &&
        !e.target.closest(".profile-options")
      ) {
        setProfileOptionPreview(false);
      }
    });
  }, []);

  // CUSTOM COMPONENT
  const feedbackActionsBox = () => (
    <>
      <button className="report-btn" onClick={setReportPreviewHandler}>
        Report a problem
      </button>
      {/* <AntdDropdown
        onOpenChange={(open) => setHelpDropdownPreview(open)}
        getPopupContainer={(trigger) => trigger.parentNode}
        dropdownRender={() => (
          <div className="help-center-dropdown">
            <h4>Help Center</h4>
            <button onClick={setFeedbackPreviewHandler}>Give Feedback</button>
          </div>
        )}
        trigger={["click"]}
      > */}
      <button className="report-btn" onClick={setFeedbackPreviewHandler}>
        Give feedback{" "}
        {/* <FontAwesomeIcon
            icon={helpDropdownPreview ? faChevronUp : faChevronDown}
          /> */}
      </button>
      {/* </AntdDropdown> */}
    </>
  );

  return (
    <>
      <div className="header-tagline">{feedbackActionsBox()}</div>
      <header className="dashboard-header">
        <div className="left-box">
          <Link to={DASHBOARD_ROUTE}>
            <div className="logo-box">
              <img src={Images.Logo} alt="" />
              <h1>
                <span>MyAI</span>Builder
              </h1>
            </div>
          </Link>
        </div>
        <div className="right-box">
          {feedbackActionsBox()}
          <Dropdown
            className="create-dropdown"
            placeholder="Create new"
            options={createDropdownOptions}
            optionBox={Option}
            selectHandler={({ onClick }) => onClick()}
          />
          <div className="user-profile">
            <button
              className="profile-box"
              onClick={setprofileOptionPreviewHandler}
            >
              <Avatar src={userdata?.photo_url} className="thumb">
                {userdata?.name?.charAt(0) ?? userdata?.id?.charAt(0)}
              </Avatar>
              <span className={clsx("caret", profileOptionPreview && "active")}>
                <FontAwesomeIcon icon={faChevronDown} />
              </span>
            </button>
            <ul
              className={clsx(
                "profile-options",
                profileOptionPreview && "visible"
              )}
            >
              <li>
                <button onClick={setprofileOptionPreviewHandler}>
                  {userdata?.name}
                </button>
              </li>
              {/* <li>
              <button onClick={setprofileOptionPreviewHandler}>Settings</button>
            </li> */}
              {/* <li>
              <button onClick={setprofileOptionPreviewHandler}>Logout</button>
            </li> */}
            </ul>
          </div>
        </div>
        <button
          className={clsx("toggle-btn", !sidebarPreview && "active")}
          onClick={setSidebarPreview}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <Feedback
          preview={feedbackPreview}
          previewHandler={setFeedbackPreviewHandler}
        />
        <ReportProblem
          preview={reportPreview}
          previewHandler={setReportPreviewHandler}
        />
      </header>
    </>
  );
};

export default Header;
