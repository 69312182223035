import React, { useState, useEffect } from "react";
import { Button } from "../../../";
import { CustomDispatch } from "../../../../helpers";
import { createProjectRequest } from "../../../../redux/slicers/project";
import { convertObjectToFormData } from "../../../../utils";
import {
  generateUploadURLRequest,
  uploadMediaRequest,
} from "../../../../redux/slicers/general";
import { manipulateCreateProjectData } from "../../../../data-manipulator/project";

const CreateProjectStep = ({
  data = {},
  previousStepHander = () => {},
  nextStepHandler = () => {},
  errorHandler = () => {},
}) => {
  // STATES
  const [uploadedFiles, setUploadedFiles] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);

  // CUSTOM DISPATCH
  const [createProject, createLoader] = CustomDispatch(createProjectRequest);
  const [uploadMedia, uploadLoader] = CustomDispatch(uploadMediaRequest);
  const [generateUrl, generateLoader] = CustomDispatch(
    generateUploadURLRequest
  );

  // CONST VALS
  const isLoading = uploadLoader || generateLoader || createLoader;

  // HELPERS
  const createProjectHelper = () => {
    const payload = {
      resource: "project",
      method: "create",
      details: manipulateCreateProjectData(data),
    };
    createProject({
      payload,
      success: (data) => {
        nextStepHandler(data);
      },
      error: (data) => {
        errorHandler(data);
      },
    });
  };

  const uploadMediaHelper = (data, files) => {
    setTotalFiles(data.length);

    data.forEach((filedata, index) => {
      const object = {
        ...filedata,
        file: files[index],
      };
      delete object.name;
      delete object.url;
      const payload = convertObjectToFormData(object);
      uploadMedia({
        url: filedata.url,
        payload,
        success: () => {
          setUploadedFiles((uploadedFiles) => uploadedFiles + 1);
        },
      });
    });
  };
  const generateUrlHelper = (files) => {
    const payload = {
      files: files.map((item) => item.name),
      client_method: "upload",
    };
    generateUrl({
      payload,
      success: (data) => {
        uploadMediaHelper(
          data,
          files.map((item) => item.file)
        );
      },
    });
  };

  // HANDLERS
  const createProjectHandler = () => {
    if (data.files?.length >= 1) {
      generateUrlHelper([...data.files]);
      return;
    }
    createProjectHelper();
  };

  // HOOKS
  useEffect(() => {
    if (uploadedFiles >= 1 && uploadedFiles === totalFiles) {
      createProjectHelper();
    }
  }, [uploadedFiles, totalFiles]);

  return (
    <div className="create-project-step">
      <div className="content-wrapper">
        <h2>Create project</h2>
        <p>You are about to create the following project:</p>
        <ul className="project-specs">
          <li>
            <span className="title">Project name:</span>
            <span className="val">{data.projectName}</span>
          </li>
          <li>
            <span className="title">Model:</span>
            <span className="val">{data.modelName}</span>
          </li>
          {/* <li>
            <span className="title">Database:</span>
            <span className="val">{data.database}</span>
          </li> */}
          <li>
            {/* <span className="title">Files upload:</span>
            <span className="val">
              {data.files?.length >= 1 ? "Yes" : "No"}
            </span> */}
          </li>
          <li>
            {/* <span className="title">URL crawl/indexed:</span>
            <span className="val">{data.urls?.length >= 1 ? "Yes" : "No"}</span> */}
          </li>
        </ul>
      </div>
      <div className="bottom-wrapper">
        <Button className="previous-step-btn" onClick={previousStepHander}>
          Back
        </Button>
        <Button
          isLoading={isLoading}
          invertedTheme
          loaderColor="#000"
          className="next-step-btn"
          onClick={createProjectHandler}
        >
          Create project
        </Button>
      </div>
    </div>
  );
};

export default CreateProjectStep;
