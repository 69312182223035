import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CardBox = ({
  title = "",
  smallTitle = false,
  icon,
  description = "",
  children,
}) => {
  return (
    <div className="card-box">
      <h2 className={`title ${smallTitle ? "small" : ""}`}>
        {!!icon && <FontAwesomeIcon icon={icon} className="thumb" />}
        {title}
      </h2>
      {!!description && <p className="description">{description}</p>}
      <div className="content">{children}</div>
    </div>
  );
};

export default CardBox;
