import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';
import { InputBox, MessageBox } from '..';
import { useDispatch, useSelector } from 'react-redux';
import { WebSocketHandlers } from '../../../../../services/web-sockets';
import { Loader } from '../../../../../components';
import {
  createChatRequest,
  getMessagesRequest,
  recieveMessageRequest,
  sendMessageRequest,
  clearMessages,
} from '../../../../../redux/slicers/chat';
import {
  MESSAGE_TIMEOUT,
  MESSAGE_TIMEOUT_ERROR,
  MESSAGE_TYPE_JSON,
} from '../../../../../constants';
// import { navigateToLogin } from "../../../../../utils";
import { Helmet } from 'react-helmet';
import { Images } from '../../../../../theme';
import SplashScreen from '../splash-screen';
import { CustomDispatch } from '../../../../../helpers';
import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import Avatar from '../avatar-box';
import { AnamEvent } from '@anam-ai/js-sdk/dist/module/types';
import { unsafe_createClientWithApiKey } from '@anam-ai/js-sdk';
import {
  deleteUploadMediaRequest,
  generatePromptUploadURLRequest,
  getMediaStatusRequest,
  uploadMediaRequest,
} from '../../../../../redux/slicers/general';
import {
  convertObjectToFormData,
  generateGuid,
  promptFilesValidation,
  toastAlert,
} from '../../../../../utils';
import './styles.scss';
let flag = false;

const PERSONAS = [
  {
    name: 'Alex',
    id: '67fb9278-e049-4937-9af1-d771b88b6875',
  },
  {
    name: 'Maya',
    id: '1a5588b4-a717-468c-ad8b-03b323e78e78',
  },
  {
    name: 'Leo',
    id: '773a8ca8-efd8-4449-9305-8b8bc1591475',
  },
  {
    name: 'Sophie',
    id: 'ebc07e30-64a2-40e3-9584-28f44d62ee0c',
  },
  {
    name: 'Aria',
    id: '1e9a0de0-1190-4ede-b946-ff451430848e',
  },
];

const PromptThumb = () => (
  <img className="logo" src={Images.LogoThumb} alt="" />
);

const ChatBox = ({ audioPreviewHandler }) => {
  // STATES
  const [sessionId, setSessionId] = useState(null);
  const [reversedScrolling, setReversedScrolling] = useState(false);
  const [initLoad, setinitLoad] = useState(true);
  const [scrollToKey, setScrollToKey] = useState();
  const [moreMessagessData, setmoreMessagesData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isAvatarLoading, setIsAvatarLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [chunks, setChunks] = useState([]);
  const [completeMessage, setCompleteMessage] = useState(null);
  const [toggleAvatar, setToggleAvatar] = useState(false);
  const [isAvatarTalking, setIsAvatarTalking] = useState(false);
  const [selectedPersona, setSelectedPersona] = useState(PERSONAS[0]);
  const [completedChunks, setCompletedChunks] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLoading, setFileLoading] = useState({});
  const [showTextHighlight, setShowTextHighlight] = useState([]);

  // CUSTOM DISPATCH
  const [createChat, chatLoader] = CustomDispatch(createChatRequest);
  const [getMessages, messgesLoader] = CustomDispatch(getMessagesRequest);
  const [uploadMedia] = CustomDispatch(uploadMediaRequest);
  const [generateUrl] = CustomDispatch(generatePromptUploadURLRequest);
  const [getMediaStatus] = CustomDispatch(getMediaStatusRequest);
  const [deleteMedia] = CustomDispatch(deleteUploadMediaRequest);

  // REDUX DATA
  const selectedProject = useSelector((state) => state.project.selectedProject);
  const avatarVisibility = useRef(selectedProject.enableAvatar);
  const anamClientRef = useRef(null);
  const anamStreamRef = useRef(null);
  const { messages } = useSelector((state) => state.chat);

  // CONST VALS
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const messageBox = useRef(null);
  const messageHistory = useRef(null);
  const toastTimerRef = useRef(null);
  const messageWrapper = useRef(null);
  const itemRefs = useRef({});
  const selectedChat = searchParams.get('chat');
  const bottomMarkerRef = useRef(null);
  const isUserAtBottomRef = useRef(true);

  const { sendJsonMessage, lastMessage, getWebSocket } = WebSocketHandlers();

  // HELPERS
  const getUploadedMediaStatus = () => {
    const payload = {
      resource: 'data',
      method: 'list_assets',
      details: {
        session_id: selectedChat ?? sessionId,
        project_id: selectedProject.id,
        db_type: 'opensearch',
      },
    };
    if (selectedQuery) payload.details['query_id'] = selectedQuery;
    getMediaStatus({
      payload,
      success: ({ data }) => {
        setSelectedFile((prevFiles) =>
          prevFiles.map((file) => {
            const newfile = data.files.find((x) => x.file_name === file.name);
            const fileStatus = newfile?.search_status;
            if (fileStatus === 'not_searchable') {
              return {
                ...file,
                error:
                  'There was an error uploading your file.' +
                  ' Please try again or contact support at ai-acceleration@asu.edu if the issue persists.',
              };
            }
            return file;
          })
        );
        setFileLoading((prevLoading) => {
          const temp = { ...prevLoading };
          Object.keys(prevLoading).forEach((key) => {
            const file = data.files.find((x) => x.file_name === key);
            const fileStatus = file?.search_status;
            if (
              fileStatus === 'searchable' ||
              fileStatus === 'not_searchable'
            ) {
              delete temp[key];
            }
          });
          return temp;
        });
      },
    });
  };

  const uploadMediaHelper = (data, files) => {
    data.forEach((filedata, index) => {
      const object = {
        ...filedata,
        file: files[index],
      };
      delete object.name;
      delete object.url;
      const payload = convertObjectToFormData(object);
      uploadMedia({
        url: filedata.url,
        payload,
        success: () => {
          setUploadedFiles((prevFiles) => [...prevFiles, filedata]);
        },
      });
    });
  };

  const generateUrlHelper = (files) => {
    const payload = {
      resource: 'data',
      method: 'chat_upload',
      details: {
        db_type: selectedProject.database,
        project_id: selectedProject.id,
        session_id: selectedChat ?? sessionId,
        files: files.map((item) => {
          return { file_name: item.name };
        }),
      },
    };
    if (selectedQuery) payload.details['query_id'] = selectedQuery;
    generateUrl({
      payload,
      promptMedia: true,
      success: (data) => {
        setSelectedQuery(data.queryId);
        uploadMediaHelper(data.files, files);
        setSelectedFile((prevFiles) =>
          prevFiles.map((file) => {
            const matchedFile = data.errorFiles[file.name];
            return matchedFile
              ? { ...file, error: data.errorFiles[file.name] }
              : file;
          })
        );
        setFileLoading((prevLoading) => {
          const temp = { ...prevLoading };
          Object.keys(prevLoading).forEach((key) => {
            if (!data.files.some((x) => x.name === key)) {
              delete temp[key];
            }
          });
          return temp;
        });
      },
      error: () => {
        setSelectedFile([]);
        setFileLoading({});
        setUploadedFiles([]);
      },
    });
  };

  const stopResponseHelper = (data, closeConnection = true) => {
    setLoading(false);
    if (closeConnection) getWebSocket().close();
    if (messageHistory.current) messageHistory.current['isLoading'] = false;
    dispatch(recieveMessageRequest(data));
    messageHistory.current = null;
  };

  const generateMessagePayloadHelper = ({
    message = '',
    isError = false,
    isLoading = false,
  }) => {
    const payload = {
      isCompleted: false,
      isLoading: isError ? false : isLoading,
      isPrompt: true,
      model: selectedProject.modelLabel,
      message,
    };
    if (isError) payload['isError'] = true;
    return payload;
  };

  const sendImageMessageHelper = (message, images, session, queryId) => {
    const uploadedimages = images.filter((x) => x.type === 'image');
    const uploadeddocs = images.filter(
      (x) => x.type !== 'image' && x.type !== 'audio'
    );
    const uploadedaudio = images.filter((x) => x.type === 'audio');
    const payload = {
      action: 'queryV2',
      session_id: session ?? selectedChat ?? sessionId,
      query_id: queryId ?? selectedQuery,
      project_id: selectedProject.id,
      query: message,
      chat_upload: {
        images: uploadedimages.map((x) => x.name),
        docs: uploadeddocs.map((x) => x.name),
        audios: uploadedaudio.map((x) => x.name),
      },
    };
    if (uploadedimages.length >= 1) {
      payload['request_type'] = 'vision';
      payload['endpoint'] = 'vision';
    }
    if (uploadedaudio.length >= 1) {
      payload['request_type'] = 'audio';
      payload['endpoint'] = 'audio';
    }
    if (MESSAGE_TYPE_JSON) {
      payload['response_format'] = { type: 'json' };
    }
    sendJsonMessage(payload);
    setSelectedQuery(null);
  };

  const sendMessageHelper = (message, session) => {
    startToastTimer();

    // create message payload
    const messagePayload = {
      action: 'queryV2',
      session_id: session ?? selectedChat,
      project_id: selectedProject.id,
      query: message,
    };

    if (MESSAGE_TYPE_JSON) {
      messagePayload['response_format'] = { type: 'json' };
      messagePayload.search_params = {
        ...messagePayload.search_params,
        output_fields: ['source_name', 'page_number', 'tags', 'url'],
      };
    }

    // send message to server
    sendJsonMessage(messagePayload);
  };

  const createChatHelper = (message, images, queryId) => {
    const name = message.split('\n')[0];
    const chatName = `${name.slice(0, 30)}${name.length > 30 ? '...' : ''}`;
    const payload = {
      method: 'create',
      details: {
        app_id: selectedProject.id,
        session_name: chatName,
      },
    };
    if (images.length >= 1) payload.details['session_id'] = sessionId;
    createChat({
      payload,
      success: (res) => {
        images.length >= 1
          ? sendImageMessageHelper(message, images, res?.id, queryId)
          : sendMessageHelper(message, res?.id);
        setSearchParams({ chat: res?.id });
      },
    });
  };

  const getMessagesHelper = () => {
    const payload = {
      method: 'describe',
      details: {
        session_id: selectedChat,
        app_id: selectedProject.id,
      },
      item_limit: 30,
    };
    if (moreMessagessData) payload['next_page'] = moreMessagessData;
    isUserAtBottomRef.current = true;
    getMessages({
      payload,
      otherkeys: generateMessagePayloadHelper({}),
      success: (data) => {
        const isReversed = data.messages?.messages?.length >= 8;
        setReversedScrolling(isReversed);
        setScrollToKey(messages?.[0]?.id);
        setmoreMessagesData(data?.nextPageData);
        if (!isReversed) scrollToBottom();
      },
      error: () => {
        setSearchParams({});
      },
    });
  };

  // HANDLERS
  const audioBoxPreviewHandler = () => {
    audioPreviewHandler();
    setSelectedFile([]);
    setUploadedFiles([]);
  };
  const scrollToBottom = () => {
    if (isUserAtBottomRef.current) {
      messageBox.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleScroll = useCallback(
    (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      const scrolledToTop = Math.abs(scrollTop) + clientHeight === scrollHeight;
      setinitLoad(false);
      if (!scrolledToTop) return;
      if (!moreMessagessData || messgesLoader) return;
      getMessagesHelper();
    },
    [getMessagesHelper, messgesLoader]
  );

  const selectFileHandler = (files) => {
    const newFileLoadingState = {};
    const promises = Array.from(files).map((file) => {
      const fileName = file.name;
      const fileType = file.type;
      const isImage = fileType.includes('image');
      const isAudio = fileType.includes('audio');
      const error = promptFilesValidation(file, true);

      if (error) {
        const mediaObj = {
          type: isImage ? 'image' : isAudio ? 'audio' : 'doc',
          data: file,
          name: fileName,
          error: error,
        };
        setSelectedFile((prevFiles) => [...prevFiles, mediaObj]);
        return Promise.reject(mediaObj);
      }

      newFileLoadingState[fileName] = true;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        setFileLoading((prevLoading) => ({
          ...prevLoading,
          ...newFileLoadingState,
        }));

        reader.onload = (e) => {
          resolve({ type: 'image', data: e.target.result, name: fileName });
        };
        reader.onerror = (e) => reject(e);

        if (isImage) {
          reader.readAsDataURL(file);
        } else {
          resolve({
            type: isAudio ? 'audio' : 'doc',
            data: file,
            name: fileName,
          });
        }
      });
    });

    Promise.all(promises)
      .then((results) => {
        setSelectedFile((prevFiles) => [...prevFiles, ...results]);
        generateUrlHelper(files);
      })
      .catch((error) => {
        console.error('Error reading files:', error);
      });
  };

  const removeSelectedFileHandler = (file) => {
    const payload = {
      resource: 'data',
      method: 'delete_assets',
      details: {
        session_id: selectedChat ?? sessionId,
        query_id: selectedQuery,
        project_id: selectedProject.id,
        db_type: 'opensearch',
        files: [{ file_name: file }],
      },
    };
    setSelectedFile((prevFiles) => prevFiles.filter((x) => x.name !== file));
    setUploadedFiles((prevFiles) => prevFiles.filter((x) => x.name !== file));
    setFileLoading(({ [file]: _, ...rest }) => rest);
    deleteMedia({ payload });
  };

  const sendMessageHandler = (message, images, queryId) => {
    const uploadedFiles = images || selectedFile;
    const promptImages = uploadedFiles.filter((x) => !x.error);
    setSelectedFile([]);
    setUploadedFiles([]);

    // save message to reducer
    const usermessage = {
      message,
      images: promptImages,
    };

    if (queryId || selectedQuery) {
      usermessage['queryId'] = queryId || selectedQuery;
    }

    dispatch(sendMessageRequest(usermessage));

    // save message to history
    const payload = generateMessagePayloadHelper({
      isLoading: true,
      images: promptImages,
    });
    messageHistory.current = payload;

    // scroll to bottom
    isUserAtBottomRef.current = true;
    scrollToBottom();

    setLoading(true);
    if (!selectedChat) {
      createChatHelper(message, promptImages, queryId);
      return;
    }
    // send image message
    if (promptImages.length >= 1) {
      sendImageMessageHelper(message, promptImages, null, queryId);
      return;
    }
    // else send text message
    sendMessageHelper(message);
  };

  const startToastTimer = () => {
    clearToastTimer();
    toastTimerRef.current = setTimeout(() => {
      setShowToast(true);
    }, MESSAGE_TIMEOUT * 1000);
  };

  const clearToastTimer = () => {
    if (toastTimerRef.current) {
      clearTimeout(toastTimerRef.current);
      toastTimerRef.current = null;
    }
  };

  const stopResponseHandler = () => {
    stopResponseHelper(messageHistory.current);
  };

  // MANIPULATORS
  const manipulateJsonMessage = (lastMessage) => {
    const data = JSON.parse(lastMessage);
    const message = data?.response;

    if ('message' in data) return;

    if (toggleAvatar) setIsAvatarTalking(true);

    setChunks((prevChunks) => {
      const newChunks = [...prevChunks, message];

      if (toggleAvatar) {
        if (!anamStreamRef.current.isActive()) {
          console.log('Stream is inactive. Restarting...');
          anamStreamRef.current =
            anamClientRef.current.createTalkMessageStream();
        }

        anamStreamRef.current.streamMessageChunk(
          message,
          message.includes(`<EOS>`)
        );
      }

      return newChunks;
    });

    // need to check if error is in data
    if ('error' in data) {
      stopResponseHelper(
        generateMessagePayloadHelper({ message: data.error, isError: true })
      );
      return;
    }

    if (message.includes(`<EOS>`)) {
      const fullMessage = chunks.join('');

      setChunks([]);
      setCompletedChunks((prev) => [...prev, fullMessage]);
      setIsProcessing(false);
      setLoading(false);
      messageHistory.current['isCompleted'] = true;
      messageHistory.current['sources'] = data?.metadata?.sources ?? [];
      messageHistory.current['id'] = data?.metadata?.query_id;
      dispatch(recieveMessageRequest(messageHistory.current));
      messageHistory.current = null;

      setTimeout(() => {
        setIsProcessing(true);
      }, 30);
      return;
    }

    if ('message' in data) return;

    const updatedmessage = messageHistory.current.message.concat(message);
    messageHistory.current = generateMessagePayloadHelper({
      message: updatedmessage,
      isLoading: false,
    });
  };

  const manipulateMessage = (lastMessage) => {
    if (lastMessage.includes(`<EOS>`)) {
      setLoading(false);
      messageHistory.current['isCompleted'] = true;
      dispatch(recieveMessageRequest(messageHistory.current));
      messageHistory.current = null;
      return;
    }
    if (lastMessage.includes(`message":`)) return;
    const message = messageHistory.current.message.concat(lastMessage);
    messageHistory.current = generateMessagePayloadHelper({
      message,
      isLoading: false,
    });
  };

  // HOOKS
  useMemo(() => {
    if (selectedChat) return;
    setSessionId(generateGuid());
  }, [selectedChat]);

  useLayoutEffect(() => {
    if (!scrollToKey || messageWrapper.current?.scrollTop > 0 || initLoad)
      return;
    itemRefs.current?.[scrollToKey]?.scrollIntoView();
  }, [scrollToKey]);

  useEffect(() => {
    if (!showToast) return;
    stopResponseHelper(
      generateMessagePayloadHelper({
        message: MESSAGE_TIMEOUT_ERROR,
        isError: true,
      })
    );
    setShowToast(false);
  }, [showToast]);

  useMemo(() => {
    if (!selectedChat) {
      dispatch(clearMessages());
      getWebSocket()?.close();
      return;
    }
    if (!lastMessage) return;
    clearToastTimer();
    // if (lastMessage.includes(`message":`)) {
    //   // navigateToLogin()
    //   return;
    // }

    // manipulate message based on type
    if (MESSAGE_TYPE_JSON) {
      return manipulateJsonMessage(lastMessage.data);
    }

    manipulateMessage(lastMessage.data);
  }, [lastMessage]);

  useEffect(() => {
    setinitLoad(true);
    setmoreMessagesData(null);
    if (isLoading) return;
    if (selectedChat) {
      getMessagesHelper();
      return;
    }
    dispatch(clearMessages());
  }, [selectedChat]);

  useEffect(() => {
    const initializeClient = async () => {
      if (!flag) {
        setIsAvatarLoading(true);

        anamClientRef.current = unsafe_createClientWithApiKey(
          selectedProject?.avatarApiKey,
          {
            personaId: selectedPersona.id,
            disableBrains: true,
          }
        );

        await anamClientRef.current.streamToVideoAndAudioElements(
          'my-video-element-id',
          'my-audio-element-id'
        );
        anamClientRef.current.addListener(AnamEvent.VIDEO_PLAY_STARTED, () => {
          anamStreamRef.current =
            anamClientRef.current.createTalkMessageStream();
        });

        anamClientRef.current.addListener(
          AnamEvent.MESSAGE_STREAM_EVENT_RECEIVED,
          (event) => {
            if (event.content.includes('<EOS>')) setIsAvatarTalking(false);
          }
        );

        flag = true;
        setIsAvatarLoading(false);
      }
    };

    // const processMessage = async () => {
    //   if (toggleAvatar && completedChunks.length > 0 && isProcessing) {
    //     try {
    //       const currentMessage = completedChunks[0];
    //       // client.talk(currentMessage)

    //       // Remove the processed message and prepare for the next one
    //       setCompletedChunks(prev => prev.slice(1));
    //       setIsProcessing(false);

    //       // If there are more messages, set up the next processing cycle
    //       if (completedChunks.length > 1) {
    //         setTimeout(() => {
    //           setIsProcessing(true);
    //         }, 100);
    //       }
    //     } catch (error) {
    //       console.error('Error processing message:', error);
    //       setIsProcessing(false);
    //     }
    //   }
    // };

    const setup = async () => {
      await initializeClient();
      // await processMessage();
    };

    if (toggleAvatar && avatarVisibility.current) {
      setup();
    }

    return () => {
      if (!toggleAvatar && anamClientRef.current != null) {
        anamClientRef.current.stopStreaming();
        anamClientRef.current.stopSession &&
          anamClientRef.current.stopSession();
      }
    };
  }, [flag, completedChunks, isProcessing, selectedPersona, toggleAvatar]);

  useEffect(() => {
    if (completedChunks.length > 0 && !isProcessing && toggleAvatar) {
      setTimeout(() => {
        setIsProcessing(true);
      }, 100);
    }
  }, [completedChunks.length, isProcessing, toggleAvatar]);

  const handlePersonaChange = (event) => {
    const persona = PERSONAS.find((p) => p.id === event.target.value);
    setSelectedPersona(persona);
    flag = false;
    if (anamClientRef.current != null) {
      anamClientRef.current.stopSession && anamClientRef.current.stopSession();
    }
  };

  const toggleAvatarHandler = () => {
    setToggleAvatar((prevMode) => {
      const newMode = !prevMode;

      if (!newMode && anamClientRef.current != null) {
        anamClientRef.current.stopStreaming();
        anamClientRef.current.stopSession &&
          anamClientRef.current.stopSession();
      } else if (newMode) {
        flag = false;
        setCompleteMessage('');
      }

      return newMode;
    });
  };
  useEffect(() => {
    let interval;
    const handleMediaChange = () => {
      const selectedFiles = selectedFile.filter((x) => !x.error);
      const pendingMedia = uploadedFiles.length >= selectedFiles.length;
      if (Object.keys(fileLoading).length > 0 && pendingMedia) {
        interval = setInterval(() => {
          getUploadedMediaStatus(true);
        }, 2000); // 2 seconds
      }
    };
    handleMediaChange();
    return () => interval && clearInterval(interval);
  }, [uploadedFiles, fileLoading, selectedQuery]);

  useEffect(() => {
    const container = messageWrapper.current;
    if (!container) return;

    const onScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = container;
      isUserAtBottomRef.current = scrollTop + clientHeight >= scrollHeight - 5;
    };

    const observerCallback = () => {
      if (isUserAtBottomRef.current) {
        scrollToBottom();
      }
    };

    const mutationObserver = new MutationObserver(observerCallback);
    const resizeObserver = new ResizeObserver(observerCallback);

    mutationObserver.observe(container, { childList: true, subtree: true });

    if (bottomMarkerRef.current) {
      resizeObserver.observe(bottomMarkerRef.current);
    }
    if (isUserAtBottomRef.current) {
      scrollToBottom();
    }
    container.addEventListener('scroll', onScroll);

    return () => {
      mutationObserver.disconnect();
      resizeObserver.disconnect();
      container.removeEventListener('scroll', onScroll);
    };
  }, [messageHistory.current?.message.length]);

  // CUSTOM COMPONENT
  return (
    <div className={clsx('chat-box', selectedFile.length >= 1 && 'selected')}>
      {!!selectedProject?.webInterface && (
        <Helmet>
          <title>{selectedProject?.webTitle}</title>
        </Helmet>
      )}
      {/* {avatarVisibility.current && (
      <div className="flex justify-end p-4">
        <select
          value={selectedPersona.id}
          onChange={handlePersonaChange}
          className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none" + 
          " focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
        >
          {PERSONAS.map(persona => (
            <option key={persona.id} value={persona.id}>
              {persona.name}
            </option>
          ))}
        </select>
      </div>
      )} */}
      {toggleAvatar && avatarVisibility.current ? (
        <Avatar isAvatarLoading={isAvatarLoading} />
      ) : (
        <div
          className={clsx('messages-wrapper', reversedScrolling && 'reversed')}
          ref={messageWrapper}
          onScroll={handleScroll}
        >
          {messgesLoader && !moreMessagessData ? (
            <Loader height="100%" />
          ) : (
            <>
              {!!reversedScrolling && (
                <div className="scroller-box" ref={messageBox} />
              )}
              {messages.length >= 1 ? (
                <div className="messages-list">
                  {!!moreMessagessData && !!messgesLoader && (
                    <Loader
                      height="30px"
                      size={10}
                      style={{ position: 'absolute', top: 10, width: '100%' }}
                    />
                  )}
                  {messages.map((message, index) => (
                    <MessageBox
                      selectedProject={selectedProject}
                      key={index}
                      data={message}
                      ref={(ref) => (itemRefs.current[message.id] = ref)}
                      isLoading={isLoading || chatLoader}
                      previousMessage={messages[index - 1]}
                      reGenerateMessage={sendMessageHandler}
                      promptThumb={<PromptThumb />}
                      showTextHighlight={showTextHighlight}
                      setShowTextHighlight={setShowTextHighlight}
                    />
                  ))}
                  {!!messageHistory.current && (
                    <MessageBox
                      data={messageHistory.current}
                      selectedProject={selectedProject}
                      scrollToBottom={scrollToBottom}
                      promptThumb={<PromptThumb />}
                      showTextHighlight={showTextHighlight}
                      setShowTextHighlight={setShowTextHighlight}
                    />
                  )}
                </div>
              ) : (
                <SplashScreen sendMessage={sendMessageHandler} />
              )}
              {!reversedScrolling && (
                <div className="scroller-box" ref={messageBox} />
              )}
            </>
          )}
          <div ref={bottomMarkerRef} className="bottom-marker" />
        </div>
      )}
      <div className="bottom-wrapper">
        <InputBox
          ref={messageWrapper}
          selectedProject={selectedProject}
          audioBoxPreviewHandler={audioBoxPreviewHandler}
          selectedFile={selectedFile}
          selectFile={selectFileHandler}
          fileLoader={fileLoading}
          removeSelectedFile={removeSelectedFileHandler}
          sendMessage={sendMessageHandler}
          stopResponse={stopResponseHandler}
          isLoading={isLoading || chatLoader}
          isAvatarTalking={isAvatarTalking}
          avatarVisibility={avatarVisibility}
          toggleAvatarHandler={toggleAvatarHandler}
          toggleAvatar={toggleAvatar}
        />
      </div>
    </div>
  );
};

export default ChatBox;
