import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, ThankyouModal } from "../../../../components";
import { useSelector } from "react-redux";
import { Checkbox, Form, Input, Radio } from "antd";
import {
  convertObjectToFormData,
  inputFieldRule,
  toastAlert,
  validateImage,
} from "../../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  reportProblemRequest,
  uploadMediaRequest,
} from "../../../../redux/slicers/general";
import { CustomDispatch } from "../../../../helpers";
import { ALERT_TYPES } from "../../../../constants";
import "./styles.scss";

const ReportProblem = ({ preview, previewHandler }) => {
  // STATES
  const [isLoading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [totalFiles, setTotalFiles] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState(0);
  const [thankyouPreview, setThankyouPreview] = useState(false);

  // REDUX DATA
  const projects = useSelector(({ project }) => project.projects);

  // CUSTOM DISPATCH
  const [submitFeedback] = CustomDispatch(reportProblemRequest);
  const [uploadMedia] = CustomDispatch(uploadMediaRequest);

  // HELPERS
  const uploadMediaHelper = (totalFiles, data, files) => {
    setTotalFiles(totalFiles.length);
    data.forEach((filedata, index) => {
      const object = {
        ...filedata,
        file: files[index],
      };
      delete object.name;
      delete object.url;
      const payload = convertObjectToFormData(object);
      uploadMedia({
        url: filedata.url,
        payload,
        success: () => setUploadedFiles((uploadedFiles) => uploadedFiles + 1),
      });
    });
  };

  // CONST VALS
  const [form] = Form.useForm();
  const uploadFileInput = useRef(null);

  // HANDLERS
  const closeHandler = () => {
    setLoading(false);
    form.resetFields();
    setSelectedFile([]);
    setTotalFiles(0);
    setUploadedFiles(0);
    previewHandler();
  };

  const provideAnotherFeedback = () => {
    setThankyouPreview(false);
    closeHandler();
  };

  const removeFileHandler = (file) => {
    setSelectedFile((prevFiles) =>
      prevFiles.filter((item) => item.name !== file.name)
    );
  };

  const selectFileHandler = (files) => {
    const promises = Array.from(files).map((file) => {
      const fileName = file.name;
      const isValid = validateImage({ file });
      if (!isValid) return null;

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve({
            type: "image",
            data: e.target.result,
            file: file,
            name: fileName,
          });
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });

    Promise.all(promises)
      .then((results) => {
        const validFiles = results.filter(Boolean);
        if (validFiles.length > 0) {
          setSelectedFile((prevFiles) => [...prevFiles, ...validFiles]);
        }
      })
      .catch((error) => {
        console.error("Error reading files:", error);
      });
  };

  const uploadFileHandler = (e) => {
    const files = e.target.files;
    let uploadedFiles = [];
    if (!files) return;

    const filesArray = Array.from(files);
    const totalfiles = selectedFile.length + filesArray.length;

    if (totalfiles > 5)
      toastAlert("You can only upload 5 files at a time.", ALERT_TYPES.ERROR);

    for (const file of filesArray) {
      const allfiles = [...selectedFile, ...uploadedFiles];
      const isExist = allfiles.find((item) => item.name === file.name);
      if (isExist) {
        toastAlert(
          `${file.name} is already uploaded. Please upload a different file.`,
          ALERT_TYPES.ERROR
        );
      } else {
        uploadedFiles.push(file);
        if (uploadedFiles.length >= 5) break;
      }
    }

    if (uploadedFiles.length > 0) selectFileHandler(uploadedFiles);
    if (uploadFileInput.current) uploadFileInput.current.value = "";
  };

  const submitHandler = (values) => {
    setLoading(true);
    try {
      const payload = {
        resource: "report",
        method: "put",
        details: {
          project_id: values.projects === "app" ? null : values.projects,
          description: values.description,
          further_contact: values.contact ?? false,
        },
      };
      if (selectedFile.length > 0) {
        const files = selectedFile.map((file) => {
          return {
            source_name: file.name,
          };
        });
        payload.details.uploaded_photo = files;
      }
      submitFeedback({
        payload,
        success(data) {
          if (selectedFile.length > 0) {
            uploadMediaHelper(
              selectedFile,
              data.files,
              payload.details.uploaded_photo
            );
            return;
          }
          closeHandler();
          setThankyouPreview(true);
        },
        error: () => setLoading(false),
      });
    } catch (err) {
      console.error(err);
    }
  };

  // HOOKS
  useEffect(() => {
    if (uploadedFiles >= 1 && uploadedFiles >= totalFiles) {
      setThankyouPreview(true);
      closeHandler();
    }
  }, [uploadedFiles, totalFiles]);

  return (
    <>
      <Modal
        width={680}
        open={preview}
        openHandler={closeHandler}
        className="report-problem customize-form"
      >
        <div className="top-header">
          <h2 className="title">Report a problem</h2>
          <p className="description">
            Help us improve by sharing details of the problem.
          </p>
        </div>
        <Form form={form} onFinish={submitHandler}>
          <h4 className="sub-title">The problem was found in</h4>
          <Form.Item
            name="projects"
            rules={inputFieldRule({
              name: "Project",
              requiredMessage: "Please select a project",
              isWhiteSpace: false,
            })}
          >
            <Radio.Group
              className="projects-list"
              options={[
                { value: "app", label: "MyAI Builder" },
                ...projects.map((project) => ({
                  value: project.id,
                  label: project.projectName,
                })),
              ]}
            />
          </Form.Item>
          <h4 className="sub-title">Describe the problem here</h4>
          <Form.Item
            name="description"
            rules={inputFieldRule({
              name: "Description",
            })}
          >
            <Input.TextArea rows={5} />
          </Form.Item>
          {/* <Form.Item name="contact" valuePropName="checked">
            <Checkbox className="get-updates">
              I would like to be contacted about this problem
            </Checkbox>
          </Form.Item> */}
          <div className="upload-photo">
            <span className="thumb">
              <FontAwesomeIcon icon={faCameraAlt} />
            </span>
            <button className="uploader">
              <input
                type="file"
                multiple
                ref={uploadFileInput}
                onChange={uploadFileHandler}
              />
              Upload photo (Optional)
            </button>
          </div>
          {selectedFile.length > 0 && (
            <div className="images-box">
              <div className="selected-images">
                {selectedFile.map((item, index) => (
                  <div className="image-item" key={index}>
                    <img src={item.data} alt={item.name} />
                    <button
                      className="remove-btn"
                      onClick={() => removeFileHandler(item)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="action-box">
            <Button type="button" onClick={closeHandler}>
              Cancel
            </Button>
            <Button boldText invertedTheme isLoading={isLoading}>
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
      <ThankyouModal
        preview={thankyouPreview}
        feedbackHandler={provideAnotherFeedback}
        previewHandler={() => setThankyouPreview(false)}
      />
    </>
  );
};

export default ReportProblem;
