import React, { useState } from "react";
import { Modal, Button, UploadBox } from "../../";
import { Form, Input, Radio } from "antd";
import { inputFieldRule } from "../../../utils";
import "./styles.scss";

const CreateBasicProject = ({ preview, previewHandler }) => {
  // STATES
  const [uploadPreview, setuploadPreview] = useState(false);
  // CONST VALS
  const [form] = Form.useForm();

  // HANDLERS
  const closeHandler = () => {
    previewHandler();
  };
  const profileOptionHandler = (e) => {
    const val = e.target.value;
    setuploadPreview(val === 2);
  };

  return (
    <Modal
        open={preview}
        width={600}
        openHandler={closeHandler}
        className="create-basic-project-wrapper"
      >
        <div className="title-wrapper">
          <h2>Name Your AI Project</h2>
          <p>
            Creating a name and a description will help identify this AI Project
            in your project catalog, as well as help others find and use your AI
            Project as a starting template (if you wish).
          </p>
        </div>
        <Form form={form} className="customize-form">
          <label className="field-label" htmlFor="projectName">
            [Template name] name <span>*</span>
          </label>
          <Form.Item
            name="name"
            rules={inputFieldRule({ name: "Project name", max: 120 })}
          >
            <Input
              id="projectName"
              placeholder="Create a name for your project"
            />
          </Form.Item>
          <label className="field-label" htmlFor="projectDescription">
            [Template name] description <span>*</span>
          </label>
          <Form.Item
            name="description"
            rules={inputFieldRule({ name: "Project description", max: 240 })}
          >
            <Input.TextArea
              id="projectDescription"
              placeholder="Please write a brief description of your project"
            />
          </Form.Item>
          <label className="field-label" htmlFor="profileImage">
            Add A Profile Image
          </label>
          <Form.Item name="profileOption">
            <Radio.Group onChange={profileOptionHandler}>
              <Radio value={1}>
                Generate a profile image based on the title and description
              </Radio>
              <Radio value={2}>Upload an image from computer</Radio>
            </Radio.Group>
          </Form.Item>
          {!!uploadPreview && (
            <Form.Item name="profileImage">
              <UploadBox />
            </Form.Item>
          )}
          <div className="bottom-wrapper">
            <Button type="button" className="back-btn" onClick={previewHandler}>
              Back
            </Button>
            <Button type="submit" invertedTheme className="submit-btn">
              Create project
            </Button>
          </div>
        </Form>
      </Modal>
  );
};

export default CreateBasicProject;
