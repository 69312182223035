import React, { useCallback, useState, useEffect, useRef } from "react";
import { Form, Tooltip } from "antd";
import { Button } from "../../../../../components";
// import { SPEECH_ACCESS_PROJECTS } from "../../../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  // faMicrophone,
  faPaperclip,
  faSquare,
  faUser,
  // faStop,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { faFile, faFileAudio } from "@fortawesome/free-regular-svg-icons";
import { toastAlert } from "../../../../../utils";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useSelector } from "react-redux";
import { ALERT_TYPES } from "../../../../../constants";
import "./styles.scss";
import clsx from "clsx";
import { BounceLoader } from "react-spinners";
// import { Icons } from "../../../../../theme";

const ImageBox = ({ item, isFileLoading, removeSelectedFile }) => {
  const isImage = item.type === "image" && !item.error;
  const isAudio = item.type === "audio";
  const docTitle =
    item.type === "image" ? "Image" : isAudio ? "Audio" : "Document";

  const box = (
    <div className={clsx("media-box", isImage ? "image" : "file")}>
      {!!isFileLoading && (
        <div className="media-progress">
          <BounceLoader color="#fff" size={15} />
        </div>
      )}
      <button
        className="delete-btn"
        onClick={() => removeSelectedFile(item.name)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
      {isImage ? (
        <img src={item.data} alt={item.name} />
      ) : (
        <>
          <div className={`thumb ${item.error ? "error-thumb" : ""}`}>
            <FontAwesomeIcon
              icon={item.error ? faTimes : isAudio ? faFileAudio : faFile}
            />
          </div>
          <div className="detail">
            <h4>{item.name}</h4>
            <p>{docTitle}</p>
          </div>
        </>
      )}
    </div>
  );
  return item?.error ? (
    <Tooltip title={item.error} placement="topLeft">
      {box}
    </Tooltip>
  ) : (
    box
  );
};

const InputBox = React.forwardRef(
  (
    {
      selectedProject,
      sendMessage,
      stopResponse,
      isLoading,
      isAvatarTalking,
      toggleAvatarHandler,
      toggleAvatar,
      removeSelectedFile,
      selectFile,
      selectedFile = [],
      fileLoader,
      audioBoxPreviewHandler,
      avatarVisibility
    },
    ref
  ) => {
    // STATES
    const [message, setMessage] = useState("");

    // REDUX DATA
    const fieldsView = useSelector(({ project }) => project.fieldsVisibility);
    const fieldsValues = useSelector((state) => state.project.fieldsValues);

    // CONST VALS
    const audioBoxPreview = true;
    const [form] = Form.useForm();
    const uploadFileInput = useRef(null);
    const isFilesLoading = Object.values(fileLoader).length !== 0;
    const {
      transcript,
      listening,
      resetTranscript,
      // browserSupportsSpeechRecognition,
      browserSupportsContinuousListening,
    } = useSpeechRecognition();
    const inputPlaceholder = listening
      ? "Listening..."
      : fieldsView.input
      ? fieldsValues.input
      : "";

    const prevTranscriptRef = useRef(transcript);

    // HANDLERS
    const recordingHandler = () => {
      if (listening) {
        SpeechRecognition.stopListening();
        resetTranscript();
        return;
      }
      setMessage("");
      SpeechRecognition.startListening({
        continuous: browserSupportsContinuousListening,
      });
    };

    useEffect(() => {
      if (toggleAvatar && avatarVisibility.current) {
        recordingHandler();
      }
    }, [toggleAvatar]);

    useEffect(() => {
      if (isAvatarTalking) {
        if(listening){
          SpeechRecognition.stopListening();
          resetTranscript();
          return;
        }
      }
      else if(isAvatarTalking===false && toggleAvatar){
        recordingHandler();
      }
    }, [isAvatarTalking]);

    // const uploadImageHandler = (e) => {
    //   const files = e.target.files;
    //   let uploadedfiles = [];
    //   if (!files) return;
    //   Array.from(files).forEach((item) => {
    //     if (imageValidation(item)) uploadedfiles.push(item);
    //   });
    //   selectImage(uploadedfiles);
    // };
    // const setSelectedChatOptionHandler = (item) => {
    //   setChatOption(item);
    //   setChatOptionPreview(false);
    // };
    // const setChatOptionPreviewHandler = () => {
    //   setChatOptionPreview(!chatOptionPreview);
    // };
    // const recordingHandler = () => {
    //   if (listening) {
    //     SpeechRecognition.stopListening();
    //     resetTranscript();
    //     return;
    //   }
    //   setMessage("");
    //   SpeechRecognition.startListening({
    //     continuous: browserSupportsContinuousListening,
    //   });
    // };
    const uploadFileHandler = (e) => {
      const files = e.target.files;
      let uploadedFiles = [];
      if (!files) return;

      const filesArray = Array.from(files);
      const totalfiles = selectedFile.length + filesArray.length;

      if (totalfiles > 5)
        toastAlert("You can only upload 5 files at a time.", ALERT_TYPES.ERROR);

      for (const file of filesArray) {
        const allfiles = [...selectedFile, ...uploadedFiles];
        const isExist = allfiles.find((item) => item.name === file.name);
        const isImageExist = allfiles.some((x) => x.type.includes("image"));
        const isAudioExist = allfiles.some((x) => x.type.includes("audio"));
        if (isExist) {
          toastAlert(
            `${file.name} is already uploaded. Please upload a different file.`,
            ALERT_TYPES.ERROR
          );
        } else if (file.type.includes("audio") && isImageExist) {
          toastAlert(
            `You can't upload audio file with image file.`,
            ALERT_TYPES.ERROR
          );
        } else if (file.type.includes("image") && isAudioExist) {
          toastAlert(
            `You can't upload image file with audio file.`,
            ALERT_TYPES.ERROR
          );
        } else {
          uploadedFiles.push(file);
          if (uploadedFiles.length >= 5) break;
        }
      }

      if (uploadedFiles.length > 0) selectFile(uploadedFiles);
      if (uploadFileInput.current) uploadFileInput.current.value = "";
    };

    const setMessageHandler = (e) => {
      setMessage(e.target.value);
    };

    const generateMessageHandler = () => {
      // if (isLoading) return stopResponse();
      if (message.trim() === "") return;
      if (isFilesLoading) return;

      setMessage("");
      sendMessage(message, selectedFile);
      resetTranscript();
      // if (listening) {
      //   SpeechRecognition.stopListening();
      //   resetTranscript();
      // }
    };

    const toggleAvatarHandlerCall = () => {
      toggleAvatarHandler();
      if (listening) {
        SpeechRecognition.stopListening();
        resetTranscript();
        return;
      }
    };

    const handleKeyPress = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        generateMessageHandler();
      }
    };

    // HOOKS
    const inputRef = useCallback(
      (node) => {
        if (!node) return;
        // set textarea height
        node.style.height = "20px";
        const nodeHeight = node.scrollHeight;
        node.style.height = nodeHeight + "px";

        // set chat wrapper height
        if (!ref || !ref.current) return;
        const contentSpacing = window.innerWidth > 768 ? 95 : 65;
        const screenHeight = window.innerWidth > 768 ? 130 : 115;
        const defaultHeight =
          selectedFile.length > 0
            ? screenHeight + contentSpacing
            : screenHeight;

        const textareaHeight =
          nodeHeight > 20 ? nodeHeight - 40 : nodeHeight - 20;
        const calcheight = textareaHeight + defaultHeight;
        ref.current.style.height = `calc(100% - ${calcheight}px)`;
      },
      [message, selectedFile]
    );

    useEffect(() => {
      if (transcript !== "" && transcript !== prevTranscriptRef.current) {
        setMessage(transcript);
        prevTranscriptRef.current = transcript; 
      }
    }, [transcript]);
  
    useEffect(() => {
      if (message !== "" && listening && toggleAvatar) { 
        const timer = setTimeout(() => {
          generateMessageHandler(); 
        }, 2000);
  
        return () => {
          clearTimeout(timer);
          prevTranscriptRef.current = "";
        };
      }
    }, [message]);

    return (
      <div className="input-wrapper">
        <div className="inputbox-wrapper">
          <div className={clsx("input-box", audioBoxPreview && "reduced")}>
            <Form className="input-form" form={form}>
              <label htmlFor="file-uploader" className="upload-button">
                Upload File
                <input
                  type="file"
                  multiple
                  ref={uploadFileInput}
                  // disabled={fileLoading}
                  id="file-uploader"
                  onChange={uploadFileHandler}
                />
                <Tooltip
                  overlayInnerStyle={{
                    width: 400,
                  }}
                  title={
                    <>
                      Upload a document or image to use in this chat session. If
                      you&apos;d like the document to remain accessible across all
                      chats, you can add it to the knowledge base.
                      <br />
                      <br />
                      Supported formats: Documents (e.g., DOCX, PDF, etc), Audio
                      (MP3, WAV), Images (PNG, JPG, JPEG).
                    </>
                  }
                  placement="topLeft"
                >
                  <FontAwesomeIcon className="upload-icon" icon={faPaperclip} />
                </Tooltip>
              </label>
              {!!avatarVisibility.current && (
              <Button
                className="send-btn"
                invertedTheme
                boldText
                onClick={toggleAvatarHandlerCall}
              >
                {/* <span>{generateBtnText}</span> */}
                <FontAwesomeIcon icon={faUser} />
              </Button>
            )
              }
              {/* PROMPT GENERATOR */}
              <textarea
                id="input-box"
                className="ant-input"
                ref={inputRef}
                placeholder={inputPlaceholder}
                value={message}
                onChange={setMessageHandler}
                onKeyDown={handleKeyPress}
              />
              {/* <Tooltip
                  title={
                    !browserSupportsSpeechRecognition
                      ? "Browser doesn't support speech recognition"
                      : listening
                      ? "Stop microphone"
                      : "Use microphone"
                  }
                >
                  <button
                    className="upload-button"
                    onClick={recordingHandler}
                    disabled={!browserSupportsSpeechRecognition}
                  >
                    <FontAwesomeIcon
                      className="upload-icon"
                      icon={listening ? faStop : faMicrophone}
                    />
                  </button>
              </Tooltip> */}
              {/* GENERATE BUTTON */}
              <Button
                type="submit"
                className="send-btn"
                invertedTheme
                boldText
                onClick={generateMessageHandler}
                disabled={
                  !!(message.trim() === "" || isFilesLoading) && !isLoading
                }
              >
                {/* <span>{generateBtnText}</span> */}
                <FontAwesomeIcon icon={isLoading ? faSquare : faArrowUp} />
              </Button>
            </Form>
            <div className="images-box">
              <div className="selected-images">
                {selectedFile.map((item, index) => {
                  const isFileLoading = fileLoader[item.name];
                  return (
                    <ImageBox
                      key={index}
                      item={item}
                      isFileLoading={isFileLoading}
                      removeSelectedFile={removeSelectedFile}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          {/* {audioBoxPreview && avatarVisibility.current && (
            <Tooltip title="Use voice mode">
              <button className="voice-toggle" onClick={audioBoxPreviewHandler}>
                <img src={Icons.Voice} alt="" />
              </button>
            </Tooltip>
          )} */}
        </div>
        {/* PROMPT TAGLINE */}
        <div
          className="tagline"
          style={{ opacity: fieldsView.desclaimer ? 1 : 0 }}
        >
          {fieldsValues.desclaimer}
          {/* <span className="ml-5">Report an issue.</span> */}
        </div>
      </div>
    );
  }
);

InputBox.displayName = "InputBox";

export default InputBox;
