import React, { useState } from 'react';
import { Button, Modal } from '../../../../components';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import AccessDetail from './detail';
import clsx from 'clsx';
import SharedList from './shared-list';
import { CustomDispatch } from '../../../../helpers';
import { removeProjectUserRequest } from '../../../../redux/slicers/project';
import useGetProjectAccessList from '../../../../hooks/useGetProjectAccessList';
import './styles.scss';

const AccessModal = ({ preview, previewHandler }) => {
  // STATES
  const [accessListPreview, setAccessListPreview] = useState(false);
  const [removeUserPreview, setRemoveUserPreview] = useState(false);
  const [selectedRemovedUsers, setselectedRemovedUsers] = useState([]);

  // REDUX DATA
  const data = useSelector(({ project }) => project.selectedProject);

  // CUSTOM DISPATCH
  const [removeUser, removeLoader] = CustomDispatch(removeProjectUserRequest);

  // HELPERS
  const {
    isInProgress: isAccessListInProgress,
    data: users,
    reload: reloadAccessList
  } = useGetProjectAccessList(data.id);

  // HANDLERS
  const accessListPreviewHandler = () => {
    setAccessListPreview(!accessListPreview);
  };

  const backHandler = () => {
    setAccessListPreview(false);
  };

  const removeUsersHandler = () => {
    const removedusers = selectedRemovedUsers.map((user_id) => {
      return {
        user_id: user_id,
        role: users.find((x) => x.user_id === user_id)?.role
      };
    });
    if (removedusers.length < 1) return;
    const payload = {
      resource: 'access',
      method: 'remove',
      details: {
        project_id: data?.id,
        users: removedusers
      }
    };
    removeUser({
      payload,
      success() {
        setselectedRemovedUsers([]);
        setRemoveUserPreview(false);
        setAccessListPreview(false);
        reloadAccessList();
      }
    });
  };

  // HOOKS

  return (
    <>
      <Modal
        width={750}
        open={!!preview && !removeUserPreview}
        openHandler={previewHandler}
        className="access-modal customize-form"
      >
        <div className={clsx('top-header', accessListPreview && 'access-list')}>
          <div className={clsx('title-container')}>
            <h2 className={clsx('title', accessListPreview && 'bar-left')}>
              {!!accessListPreview && (
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="back-btn"
                  onClick={backHandler}
                />
              )}
              Share {data?.projectName}
            </h2>
            {!accessListPreview && (
              <button
                className="view-all-users"
                onClick={accessListPreviewHandler}
              >
                View all users with access
              </button>
            )}
          </div>
          {!accessListPreview && (
            <p className="description">
              Select users that will have access to your project
            </p>
          )}
        </div>
        {!!accessListPreview ? (
          <SharedList
            isLoading={isAccessListInProgress}
            selectedUsers={selectedRemovedUsers}
            setselectedUsers={setselectedRemovedUsers}
            removeUserPreviewHandler={() => setRemoveUserPreview(true)}
            users={users}
          />
        ) : (
          <AccessDetail
            reloadAccessList={reloadAccessList}
            users={users ?? []}
          />
        )}
      </Modal>
      <Modal
        width={650}
        open={removeUserPreview}
        openHandler={() => setRemoveUserPreview(false)}
        className="remove-access customize-form"
      >
        <h2 className="title">Remove collaborators</h2>
        <p>
          Are you sure you want to delete{' '}
          <b>{selectedRemovedUsers.length} collaborators?</b> This action can
          not be undone.
        </p>
        <div className="action-box">
          <Button
            boldText
            className="back-btn"
            onClick={() => setRemoveUserPreview(false)}
          >
            Cancel
          </Button>
          <Button
            boldText
            invertedTheme
            isLoading={removeLoader}
            onClick={removeUsersHandler}
          >
            Remove
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AccessModal;
