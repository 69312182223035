import React from "react";
import { Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

const LabelBox = ({ title = "", name = "", message = "", heading }) => {
  const innerContent = () => (
    <>
      {title}
      {!!message && (
        <Tooltip placement="top" title={message}>
          <span className="help">
            <FontAwesomeIcon icon={faQuestionCircle} />
          </span>
        </Tooltip>
      )}
    </>
  );
  return heading ? (
    <h4 className="form-label">{innerContent()}</h4>
  ) : (
    <label className="form-label" htmlFor={name}>
      {innerContent()}
    </label>
  );
};

export default LabelBox;
