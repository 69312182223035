import React, { useState } from "react";
import { Backdrop } from "../";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
} from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import "./styles.scss";

const ExpandField = ({ title = "", children }) => {
  // STATES
  const [expandInstruction, setExpandInstruction] = useState(false);

  // HANDLERS
  const expandInstructionHandler = () =>
    setExpandInstruction(!expandInstruction);

  return (
    <div
        className={clsx(
          "expanded-field-wrapper",
          expandInstruction && "expanded"
        )}
      >
        <div className="popup-box">
          {!!expandInstruction && <Backdrop onClick={expandInstructionHandler} />}
          <div className="popup">
            <div className="popup-title">{title}</div>
            <button
              type="button"
              className="close-btn"
              onClick={expandInstructionHandler}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            {children}
            <button
              type="button"
              className="toggle"
              onClick={expandInstructionHandler}
            >
              <FontAwesomeIcon
                icon={
                  expandInstruction
                    ? faDownLeftAndUpRightToCenter
                    : faUpRightAndDownLeftFromCenter
                }
              />
            </button>
          </div>
        </div>
      </div>
  );
};

export default ExpandField;
