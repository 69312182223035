import React, { useState } from 'react';
import { Image } from 'antd';
import clsx from 'clsx';

import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as filledStar } from '@fortawesome/free-solid-svg-icons';
import { PROJECT_SECTIONS } from '../../../../constants';
import { Button } from '../../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { timeSince } from '../../../../utils';
import { CustomDispatch } from '../../../../helpers';
import { editProjectRequest } from '../../../../redux/slicers/project';

const Card = ({
  id = '',
  section = '',
  title = '',
  tagline,
  thumb,
  favorite,
  description = '',
  viewAll,
  lastEdited,
  role = '',
  onClick = () => {}
}) => {
  const [liked, setLiked] = useState(favorite);
  const [updateProject] = CustomDispatch(editProjectRequest);

  const sectionShared = section === PROJECT_SECTIONS.SHARED;
  const sectionRecent = section === PROJECT_SECTIONS.RECENT;

  //TODO: create a custom hook, so likes can be used in create project section.
  function toggleLike() {
    const payload = {
      resource: 'project',
      method: 'update',
      details: { project_id: id, favorite: !liked }
    };

    updateProject({
      payload,
      hideToast: true,
      error: () => setLiked((liked) => !liked)
    });

    setLiked((liked) => !liked);
  }

  const dataContent = () => (
    <>
      {/* <button onClick={toggleLike} className="like-btn">
        <FontAwesomeIcon
          icon={liked ? filledStar : emptyStar}
          className={clsx('like', liked && 'favorite')}
        />
      </button> */}

      <div className="thumb-container">
        <div
          className={clsx(
            'thumb',
            `${sectionRecent ? 'thumb-big' : 'thumb-small'}`
          )}
        >
          <Image
            src={thumb}
            alt={title}
            preview={false}
            fallback="/asu_thumb.png"
          />
        </div>
        <div className="side">
          {!!sectionRecent && (
            <>
              <p>Status</p>
              <span className={clsx('project-status', tagline)}>{tagline}</span>
            </>
          )}
          {!!sectionShared && (
            <>
              <h1 className="title">{title}</h1>
              <div>
                <span className={clsx('project-role', `project-role-${role}`)}>
                  {role}
                </span>
              </div>
            </>
          )}
        </div>
      </div>

      {!!sectionRecent && <h1 className="title">{title}</h1>}
      <p className={clsx('description', sectionShared && 'mt-15')}>
        {description}
      </p>
      {!!sectionRecent && (
        <p className="modified">
          {lastEdited === 0 ? (
            'Not edited'
          ) : (
            <>Edited {timeSince(lastEdited)}</>
          )}
        </p>
      )}
    </>
  );

  const btnText = function () {
    if (sectionRecent) return 'View project';
    else if (sectionShared) return 'View team project';
  };

  return (
    <div className="card-wrapper">
      <div
        className={clsx(
          'card',
          viewAll && 'view-all',
          sectionRecent && 'card-tall'
        )}
      >
        {viewAll ? <h1>{viewAll}</h1> : dataContent()}
      </div>

      <div className="card-footer">
        <Button
          tabIndex={0}
          onClick={onClick}
          onKeyDown={onClick}
          invertedTheme={true}
          className="bold"
          type="pill"
        >
          {btnText()}
        </Button>
      </div>
    </div>
  );
};

export default Card;
