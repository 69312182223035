import { Col, Row } from 'antd';
import { Loader } from '../../../../components';
import { PROJECT_ROLES } from '../../../../constants';
import { Card } from '.';
import { useState, useMemo } from 'react';

const NUM_PROJECT_ON_ONE_ROW = 4;

const ProjectsListing = ({
  section,
  projectRoles,
  projects,
  isLoading,
  navigateToProject,
  searchQuery
}) => {
  const [showTill, setShowTill] = useState(NUM_PROJECT_ON_ONE_ROW);

  const filteredProjects = useMemo(
    () =>
      projects.filter(
        ({ role, projectName }) =>
          projectRoles.includes(role) &&
          (!searchQuery ||
            projectName?.toLowerCase().includes(searchQuery.toLowerCase()))
      ),
    [projects, projectRoles, searchQuery]
  );

  function handleShowAllClick() {
    if (showTill === NUM_PROJECT_ON_ONE_ROW)
      setShowTill(filteredProjects.length);
    else setShowTill(NUM_PROJECT_ON_ONE_ROW);
  }

  // show loader while fetching data
  if (isLoading) return <Loader height={220} size={14} />;

  // show empty box if no projects
  if (!filteredProjects.length)
    return <h1 className="no-data">No projects found</h1>;

  return (
    <div className="cards-listing">
      <Row gutter={[16, 24]}>
        {filteredProjects
          .slice(0, showTill)
          .map(
            ({
              id,
              projectName,
              projectDescription,
              projectStatus,
              publicUrl,
              favorite,
              role,
              lastModified
            }) => {
              const isViewer = role === PROJECT_ROLES.VIEWER;
              return (
                // flex="20%"
                <Col xs={24} md={12} lg={8} xl={6} key={id}>
                  <Card
                    id={id}
                    favorite={favorite}
                    section={section}
                    tagline={projectStatus}
                    title={projectName}
                    role={role}
                    description={projectDescription}
                    lastEdited={lastModified}
                    onClick={() =>
                      navigateToProject(isViewer ? publicUrl : id, isViewer)
                    }
                  />
                </Col>
              );
            }
          )}
      </Row>
      {filteredProjects.length > NUM_PROJECT_ON_ONE_ROW && (
        <div
          className="toggle-show-all"
          tabIndex={0}
          role="button"
          onKeyDown={handleShowAllClick}
          onClick={handleShowAllClick}
        >
          <p>
            {showTill < filteredProjects.length
              ? 'View all projects'
              : 'View less'}
          </p>
        </div>
      )}
    </div>
  );
};

export default ProjectsListing;
