import "./styles.scss";

const Filter = ({ onClick, options, selected }) => {
  function handleClick({ name }) {
    onClick(name);
  }

  return (
    <div className="custom-filter">
      <p className="label">Filters: </p>
      <div className="filter-options">
        {options.map((option) => (
          <div
            role="button"
            tabIndex={0}
            onKeyDown={onClick}
            onClick={() => handleClick(option)}
            className={`filter-option ${
              option.name === selected ? "selected" : ""
            }`}
            key={option.name}
          >
            {!!option.icon && (
              <span className="filter-icon">
                <img src={option.icon} alt="filter icon" />
              </span>
            )}
            <p className="filter-text">{option.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Filter;
