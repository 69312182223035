import React, { useEffect, useState } from "react";
import { Table } from "../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faUpRightFromSquare,
  faPenToSquare,
  faCopy,
  faTrashCan,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import { CustomDispatch } from "../../../helpers";
import { useSelector } from "react-redux";
import {
  editProjectRequest,
  getProjectsRequest,
} from "../../../redux/slicers/project";
import clsx from "clsx";
import { Images } from "../../../theme";
import { faStar as emptyStar } from "@fortawesome/free-regular-svg-icons";
import { faStar as filledStar } from "@fortawesome/free-solid-svg-icons";
import {
  capitalizeFirstLetter,
  getFormattedUnixDateTime,
  getUserAsurite,
} from "../../../utils";
import { DATE_TIME2, PROJECT_ROLES, PROJECT_ROUTE } from "../../../constants";
import { Avatar, Dropdown, Image } from "antd";
import { CopyProject, DeleteProject, Header } from "./components";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

const ProjectNameCell = ({ record }) => {
  // STATES
  const [liked, setLiked] = useState(record.favorite);

  //CUSTOM DISPATCH
  const [updateProject] = CustomDispatch(editProjectRequest);

  //HANDLER
  function handleLike(e) {
    e.stopPropagation();
    const payload = {
      resource: "project",
      method: "update",
      details: { project_id: record.id, favorite: !liked },
    };
    updateProject({
      payload,
      hideToast: true,
      error: () => setLiked((liked) => !liked),
    });
    setLiked((liked) => !liked);
  }

  return (
    <div className="project-name-cell">
      <Image
        className="thumb"
        preview={false}
        src={Images.ASUPlaceholder}
        alt="placeholder img"
      />
      <div className="text-container">
        <span className="name">{record.projectName}</span>
        {/* <FontAwesomeIcon
          onClick={handleLike}
          icon={liked ? filledStar : emptyStar}
          className={clsx("like", liked && "favorite")}
        /> */}
      </div>
    </div>
  );
};

const Menu = ({
  selectedProject,
  setSelectedProject,
  navigateToProject,
  setCopyProjectPreview,
  setDeleteProjectPreview,
}) => {
  // HELPERS
  const generateMenu = () => {
    const items = [
      {
        key: "3",
        label: "Make a copy",
        icon: <FontAwesomeIcon icon={faCopy} />,
        onClick: (e) => {
          e.domEvent.stopPropagation();
          setCopyProjectPreview(selectedProject);
        },
      },
    ];
    if (selectedProject.role !== PROJECT_ROLES.VIEWER) {
      items.unshift(
        {
          key: "1",
          label: "Share",
          icon: <FontAwesomeIcon icon={faUpRightFromSquare} />,
          onClick: (e) => {
            e.domEvent.stopPropagation();
            navigateToProject(selectedProject, "?preview=access");
          },
        },
        {
          key: "2",
          label: "Edit",
          icon: <FontAwesomeIcon icon={faPenToSquare} />,
        }
      );
    }

    if (selectedProject.role === PROJECT_ROLES.OWNER) {
      items.push({
        key: "4",
        label: "Delete",
        icon: <FontAwesomeIcon icon={faTrashCan} />,
        onClick: (e) => {
          e.domEvent.stopPropagation();
          setDeleteProjectPreview(selectedProject);
        },
      });
    }

    return items;
  };

  // CONST VALS
  const items = generateMenu();

  //HANDLER
  function handleSelect(e) {
    e.stopPropagation();
    setSelectedProject(selectedProject);
  }

  return (
    <Dropdown
      menu={{
        items,
      }}
      onClick={handleSelect}
      trigger={["click"]}
      getPopupContainer={(trigger) => trigger.parentNode.parentNode}
      placement="topLeft"
      dropdownRender={(menu) => (
        <div className="project-action-dropdown">
          {React.cloneElement(menu)}
        </div>
      )}
    >
      <span className="action">
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </span>
    </Dropdown>
  );
};

const OwnerCell = ({ user }) => {
  // CONST VALS
  const userAsurite = getUserAsurite();

  return (
    <div className="project-name-cell">
      <Avatar className="thumb" src={user?.photo_url}>
        {user?.name?.charAt(0) ?? user?.id?.charAt(0)}
      </Avatar>
      <div className="text-container">
        <p className="name">
          {user.id === userAsurite ? "Me" : capitalizeFirstLetter(user.name)}
        </p>
      </div>
    </div>
  );
};

const Projects = () => {
  // STATES
  const [copyProjectPreview, setCopyProjectPreview] = useState(false);
  const [deleteProjectPreivew, setDeleteProjectPreview] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  // REDUX DATA
  const projects = useSelector(({ project }) => project.projects);
  const [searchText, setSearchText] = useState("");

  // CUSTOM DISPATCH
  const [getProjects, isLoading] = CustomDispatch(getProjectsRequest);

  // CONST VALS
  const navigate = useNavigate();

  // HANDLERS
  const navigateToProject = (selectedProject, params = "") => {
    if (selectedProject.role === PROJECT_ROLES.VIEWER) {
      window.open(selectedProject.publicUrl, "_blank");
      return;
    }
    navigate(
      PROJECT_ROUTE.replace(":projectslug", selectedProject.id + params)
    );
  };

  // HOOKS
  useEffect(() => {
    const payload = {
      resource: "project",
      method: "list",
    };
    getProjects({ payload });
  }, []);

  const filteredProjects = projects.filter((project) =>
    project.projectName.toLowerCase().startsWith(searchText)
  );

  // HANDLERS
  const copyProjectPreviewHandler = (project) => {
    setSelectedProject(project);
    setCopyProjectPreview(!copyProjectPreview);
  };

  const deleteProjectPreviewHandler = (project) => {
    setSelectedProject(project);
    setDeleteProjectPreview(!deleteProjectPreivew);
  };

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "projectName",
      width: 400,
      render: (_, record) => {
        return <ProjectNameCell record={record} />;
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (data) => capitalizeFirstLetter(data),
    },
    {
      title: "Owner",
      dataIndex: "owner",
      render: (val) => <OwnerCell user={val} />,
    },
    {
      title: "Date Updated",
      dataIndex: "lastModified",
      render: (data) => getFormattedUnixDateTime(data, DATE_TIME2),
      sorter: (a, b) => Number(a.lastModified) - Number(b.lastModified),
      sortIcon: ({ sortOrder }) => (
        <FontAwesomeIcon
          icon={sortOrder === "ascend" ? faCaretDown : faCaretUp}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "projectStatus",
      render: (data, record) => {
        return (
          <div className="project-status-cell">
            <p className={`${data}`}>{capitalizeFirstLetter(data)}</p>
            <Menu
              selectedProject={record}
              navigateToProject={navigateToProject}
              setSelectedProject={setSelectedProject}
              setCopyProjectPreview={setCopyProjectPreview}
              setDeleteProjectPreview={setDeleteProjectPreview}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="projects">
      <Header onSearch={setSearchText} />

      <Table
        onRow={(record) => {
          return {
            onClick: () => {
              navigateToProject(record);
            },
          };
        }}
        height="max-content"
        columns={tableColumns}
        data={filteredProjects}
        isLoading={isLoading}
      />

      <CopyProject
        selectedProject={selectedProject}
        preview={copyProjectPreview}
        previewHandler={copyProjectPreviewHandler}
      />

      <DeleteProject
        selectedProject={selectedProject}
        preview={deleteProjectPreivew}
        previewHandler={deleteProjectPreviewHandler}
      />
    </div>
  );
};

export default Projects;
