import React, { useState } from "react";
import { AudioBox, ChatBox } from "./components";
import "./styles.scss";

const ProjectDetail = () => {
  // STATES
  const [audioBoxPreview, setAudioBoxPreview] = useState(false);

  // HANDLERS
  const audioBoxPreviewHandler = () => {
    setAudioBoxPreview(!audioBoxPreview);
  };

  return (
    <div className="project-detail">
      {audioBoxPreview ? (
        <AudioBox previewHandler={audioBoxPreviewHandler} />
      ) : (
        <ChatBox audioPreviewHandler={audioBoxPreviewHandler} />
      )}
    </div>
  );
};

export default ProjectDetail;
