import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../../../../components";
import { Tooltip } from "antd";


const ButtonWrapper = ({ preview, title, children }) => {
  if (preview) return <Tooltip title={title}>{children}</Tooltip>;
  return children;
};

const FormActions = ({
  resetHandler = () => {},
  applyHandler = () => {},
  resetPreview = false,
  applyPreview = false,
  applyLoader = false,
}) => {
  // CUSTOM COMPONENT
  return (
    <div className="actions-wrapper">
      {/* <ButtonWrapper title="Reset changes" preview={resetPreview}>
        <button
          type="button"
          className="reset-btn"
          onClick={resetHandler}
          disabled={!resetPreview}
        >
          <FontAwesomeIcon icon={faRotateLeft} />
        </button>
      </ButtonWrapper> */}
      <ButtonWrapper title="Apply changes" preview={applyPreview}>
        <Button
          type="button"
          invertedTheme
          isLoading={applyLoader}
          loaderColor="#000"
          loaderSize={6}
          className="action-btn"
          onClick={applyHandler}
          disabled={!applyPreview}
        >
          Update settings
        </Button>
      </ButtonWrapper>
    </div>
  );
};

export default FormActions;
