import _ from 'lodash';
import {
  formatBytes,
  generateGuid,
  getAppEnv,
  getFormattedUnixDateTime,
  getUserAsurite,
  isEmptyValue,
} from '../utils';
import {
  DATABASE_OPTIONS,
  DEFAULT_SEARCH_PROMPT,
  MEDIA_OUTPUT_FIELDS,
  MEDIA_RETRIEVAL_OPTIONS,
  USED_MEDIA_OPTIONS,
  VISIBILITY_OPTIONS,
} from '../constants';
import {
  faCheck,
  faSpinner,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import dataHandlerInstance from '../services/data-handler';

export const manipulateProjectDetailData = (data) => {
  const models = dataHandlerInstance.getStore().getState().general.models;
  const appEnv = getAppEnv();
  const userAsurite = getUserAsurite();
  const projectModel = models.find(
    (model) => model.value === data.model_name
  )?.label;
  let role = '';
  if (data.role) role = data.role;
  else if (data.users) {
    role = data.users.find((user) => user.user_id === userAsurite)?.role;
  }

  let project = {
    // BASIC DETAILS
    id: data?.project_id ?? generateGuid(),
    projectName: data?.project_name ?? '',
    projectDescription: data?.description ?? '',
    projectStatus: data?.project_status ?? 'draft',
    usecase: data?.use_cases ?? [],
    enableHistory: data?.enable_history ?? false,
    publicUrl: appEnv.projectPublicHost + data?.project_id,
    advancedOptions: data?.advanced_settings ?? false,
    role,
    users: data.users ?? [],
    lastModified: Number(data.last_modified),
    favorite: data.favorite || false,
    owner: data.owner_details ?? {},
    // MODEL DETAILS
    model: data?.model_name ?? '',
    modelLabel: projectModel ?? data?.model_name ?? '',
    provider: data?.model_provider ?? '',
    customizePrompt: data?.model_params?.custom_system ?? false,
    temperature: data?.model_params?.temperature * 100 ?? 0,
    // customizeTemperature: data?.model_params?.custom_temperature ?? false,
    systemPrompt: data?.model_params?.system_prompt ?? '',
    example: data?.model_params?.system_examples ?? '',
    timezone: data?.enhance_prompt?.time_zone ?? '',
    // enhancedPrompt: data?.enable_enhance_prompt ?? false,
    time: data?.enhance_prompt?.include_time ?? false,
    date: data?.enhance_prompt?.include_date ?? false,
    verbosity: data?.enhance_prompt?.verbosity ?? '',
    caching: data?.semantic_caching ?? false,

    // INTERFACE DETAILS
    // accessControl: data.access_control,
    enableApiAccess: data.api_access,
    webInterface: data?.public_interface ?? false,
    botName: isEmptyValue(data?.interface?.bot_name),
    docUpload: data?.interface?.enable_upload ?? false,
    voiceMode: data?.interface?.enable_voice ?? false,
    avatar_visibility: data?.avatar?.avatar_visibility ?? false,
    enableAvatar: data?.avatar?.enable_avatar ?? false,
    avatarApiKey: data?.avatar?.api_key ?? '',
    webTitle: data?.interface?.title,
    publicDescription: data?.interface?.description,
    desclaimerMessage: data?.interface?.disclaimer ?? '',
    splashText: isEmptyValue(data?.interface?.splash_text),
    inputBoxPlaceholder: data?.interface?.input_placeholder ?? '',
    generateButtonText: data?.interface?.generate_button_text ?? '',
    starterGroups: data?.interface?.starter_groups ?? [],
    // uiBuilder: data?.interface?.enable_starters ?? false,
    loggingEnabled: data?.interface?.logging
      ? Object.keys(data?.interface?.logging).filter(
          (key) => data?.interface?.logging[key]
        )
      : [],

    // ACCESS DETAILS
    visibility: data.visibility,
    group: data?.group_id ?? 'none',

    // MEDIA DETAILS
    enableSearch: data?.enable_search ?? false,
    database: data?.search_params?.db_type ?? '',
    tags: data?.search_params?.tags ?? [],
    showSources: data?.search_params?.output_fields?.length >= 1 ? true : false,
    promptMode: data?.search_params?.prompt_mode ?? '',
    promptModeLabel:
      USED_MEDIA_OPTIONS.find(
        (x) => x.value === data?.search_params?.prompt_mode
      ) ?? USED_MEDIA_OPTIONS[0],
    searchPrompt: isEmptyValue(
      data?.search_params?.search_prompt,
      DEFAULT_SEARCH_PROMPT
    ),
    topK: (data?.search_params?.top_k ?? 3) * 2,
    expressions: data?.search_params?.expr ?? '',
    retrievalType: isEmptyValue(
      data?.search_params?.retrieval_type,
      MEDIA_RETRIEVAL_OPTIONS[0].value
    ),
  };

  console.log("aaa",project)
  return project;
};

export const manipulateProjectListing = (data) => {
  let projects = [];
  data.forEach((element) => {
    projects.unshift(manipulateProjectDetailData(element));
  });

  // sort projects by last modified date
  projects.sort((a, b) => b.lastModified - a.lastModified);

  return projects;
};

export const manipulateCreateProjectData = (data, isUpdate, files, tags) => {
  let search_params = {
    db_type: DATABASE_OPTIONS[1].value,
    top_k: data?.topk ?? 3,
  };
  if (files) search_params['source_names'] = files;
  if (tags) search_params['tags'] = tags;
  let project = {
    project_name: data.projectName,
    description: data.projectDescription,
    visibility: isUpdate ? data.visibility : VISIBILITY_OPTIONS[0].value, // set private on create project
    group_id: data.group,
    use_cases: data.usecase,
    model_provider: data?.provider,
    model_name: data.model,
    urls: data.urls ?? [],
    public_interface: true,
    model_params: {
      custom_system: data?.systemPrompt,
      system_examples: '',
      system_prompt: data?.systemPrompt ?? '',
      temperature: data.temperature / 100,
    },
    interface: {
      title: data.projectName,
    },
    enable_search: data?.enableSearch ?? false,
    enable_history: data?.enableHistory ?? true,
    search_params,
  };
  if (isUpdate) project['project_id'] = data.id;
  if (data?.webInterface) {
    project['interface'] = {
      bot_name: data.botName,
      interface_url: 'to be generated',
      title: data.projectName,
      description: data.projectDescription,
      disclaimer: data.desclaimerMessage,
      splash_text: data.splashText,
      input_placeholder: data.inputBoxPlaceholder,
      generate_button_text: data.generateButtonText,
      logging: {
        database: data.loggingEnabled.includes('database'),
        analytics: data.loggingEnabled.includes('analytics'),
        details: data.loggingEnabled.includes('details'),
      },
    };
  }
  return project;
};

export const manipulateMediaFileData = (data) => {
  if (_.isEmpty(data)) return {};

  const filetags = data?.search_tags ?? [];
  const isSearchable = data?.search_status === 'searchable';
  const isFailed =
    data?.search_status === 'not_searchable' ||
    data?.search_status === 'isearch data not available' ||
    data?.search_status === 'iSearch data unavailable' ||
    data?.search_status === false ||
    data?.search_status === 'error';

  let file = {
    id: data?.id ?? generateGuid(),
    name: data?.file_name ?? '',
    size: formatBytes(data?.file_size),
    raw_size: data?.file_size,
    uploadDate: getFormattedUnixDateTime(
      data?.time_modified ?? data?.time_loaded
    ),
    tags: data?.search_tags ?? [],
    selected: false,
    exposed: data?.visible ?? false,
    referenced: data?.selected ?? false,
    isSearchable,
    isFailed,
    isPending: !isSearchable && !isFailed,
    statusIcon: isSearchable
      ? faCheck
      : isFailed
      ? faTriangleExclamation
      : faSpinner,
    statusColor: isSearchable ? '#22bb33' : isFailed ? '#bb2124' : '#f0ad4e',
    status: isSearchable ? 'Uploaded' : isFailed ? 'Failed' : 'Uploading',
  };

  return { file, filetags };
};

export const manipulateMediaUrlData = (data) => {
  if (_.isEmpty(data)) return {};

  const urltags = data?.search_tags ?? [];
  const isSearchable = data?.search_status === 'searchable';
  const isFailed =
    data?.search_status === 'not_searchable' ||
    data?.search_status === 'isearch data not available' ||
    data?.search_status === 'iSearch data unavailable' ||
    data?.search_status === false ||
    data?.search_status === 'error';

  let url = {
    id: data?.id ?? generateGuid(),
    name: data?.url_name ?? '',
    indexDepth: data?.depth_level ?? 1,
    docIndexing: data?.url_document ?? false,
    uploadDate: getFormattedUnixDateTime(
      data?.time_modified ?? data?.time_loaded
    ),
    selected: false,
    exposed: data?.visible ?? false,
    referenced: data?.selected ?? false,
    tags: data?.search_tags ?? [],
    isSearchable,
    isFailed,
    isPending: !isSearchable && !isFailed,
    statusIcon: isSearchable
      ? faCheck
      : isFailed
      ? faTriangleExclamation
      : faSpinner,
    statusColor: isSearchable ? '#22bb33' : isFailed ? '#bb2124' : '#f0ad4e',
    status: isSearchable ? 'Uploaded' : isFailed ? 'Failed' : 'Uploading',
  };

  return { url, urltags };
};

export const manipulateMediaListing = (data) => {
  let files = [];
  let urls = [];
  let filesTags = [];
  let urlsTags = [];
  data?.files?.forEach((element) => {
    const { file, filetags } = manipulateMediaFileData(element);
    files.unshift(file);
    filesTags = filesTags.concat(filetags);
  });
  data?.urls?.forEach((element) => {
    const { url, urltags } = manipulateMediaUrlData(element);
    urls.unshift(url);
    urlsTags = urlsTags.concat(urltags);
  });

  filesTags = [...new Set(filesTags)];
  urlsTags = [...new Set(urlsTags)];

  const media = { files, urls };
  const globaltags = { files: filesTags, urls: urlsTags };

  return { media, globaltags };
};

export const manipulateMediaStats = (data) => {
  const files = data?.files?.map((file) => manipulateMediaFileData(file)?.file);
  const urls = data?.urls?.map((url) => manipulateMediaUrlData(url)?.url);
  const uploadingFiles = files?.filter((x) => x.isPending)?.length;
  const uploadedFiles = files?.filter((x) => x.isSearchable)?.length;
  const uploadingUrls = urls?.filter((x) => x.isPending)?.length;
  const uploadedUrls = urls?.filter((x) => x.isSearchable)?.length;

  return { uploadingFiles, uploadedFiles, uploadingUrls, uploadedUrls };
};

export const manipulateCloneProjectData = (data) => {
  const systemPrompt = data?.systemPrompt ?? '';
  let project = {
    project_name: data.projectName,
    description: data.projectDescription,
    model_name: data.model,
    model_provider: data.provider,
    enable_history: data?.enableHistory ?? false,
    advanced_settings: data.advancedOptions,
    public_interface: data?.webInterface ?? false,
    semantic_caching: data?.caching ?? false,
    enable_search: data?.enableSearch ?? false,
    api_access: data.enableApiAccess,
    visibility: data.visibility,
    use_cases: data.usecase,
    search_params: {
      db_type: data?.database,
      top_k: parseInt(((data?.topK ?? 3) / 2).toFixed(0)),
      output_fields: data.showSources ? MEDIA_OUTPUT_FIELDS : [],
      prompt_mode: data?.promptMode,
      retrieval_type: data?.retrievalType,
      search_prompt: data?.searchPrompt,
      expr: data?.expressions,
    },
    model_params: {
      custom_system: systemPrompt ? true : false,
      system_prompt: systemPrompt,
      system_examples: '',
      temperature: parseFloat((data.temperature / 100).toFixed(1)),
    },
    enhance_prompt: {
      time_zone: data.timezone,
      include_time: data.time,
      include_date: data.date,
      verbosity: data.verbosity,
    },
    avatar: {
      enable_avatar: data?.enableAvatar ?? false,
    },
    interface: {
      enable_upload: data?.docUpload ?? false,
      enable_voice: data?.voiceMode ?? false,
      bot_name: data.botName,
      title: data.webTitle ?? data.projectName,
      description: data.publicDescription,
      input_placeholder: data.inputBoxPlaceholder,
      disclaimer: data.desclaimerMessage,
      logging: {
        database: data.loggingEnabled?.includes('database'),
        analytics: data.loggingEnabled?.includes('analytics'),
        details: data.loggingEnabled?.includes('details'),
      },
    },
  };
  if (data.group) project['group_id'] = data.group;
  if (data?.starterGroups) {
    project['interface']['starter_groups'] = data.starterGroups;
  }

  return project;
};

export const manipulateProjectBasicDetailPayload = (data) => {
  const systemPrompt = data?.systemPrompt ?? '';
  let project = {
    project_id: data.id,
    project_name: data.projectName,
    model_name: data.model,
    model_provider: data.provider,
    advanced_settings: data.advanced,
    public_interface: data?.webInterface ?? false,
    semantic_caching: data?.caching ?? false,
    // TEMPORARY WORK FOR NEW UI TESTING
    enable_search: data?.enableSearch ?? false,
    model_params: {
      custom_system: systemPrompt ? true : false,
      system_prompt: systemPrompt,
      system_examples: '',
      temperature: parseFloat((data.temperature / 100).toFixed(1)),
    },
    enhance_prompt: {
      time_zone: data.timezone,
      include_time: data.time,
      include_date: data.date,
      verbosity: data.verbosity,
    },
  };
  return project;
};

export const manipulateProjectDetailPayload = (data) => {
  let project = {
    project_id: data.id,
    description: data.projectDescription,
    enable_history: data?.enableHistory ?? false,
    avatar: {
      enable_avatar: data?.enableAvatar ?? false,
    },
    interface: {
      enable_upload: data?.docUpload ?? false,
      enable_voice: data?.voiceMode ?? false,
    },
  };
  if (data.group) project['group_id'] = data.group;
  return project;
};

export const manipulateProjectModelPayload = (data) => {
  let project = {
    project_id: data.id,
    semantic_caching: data?.caching ?? false,
    // enable_enhance_prompt: data?.enhancedPrompt,
    model_params: {
      // custom_temperature: data?.customizeTemperature,
      system_examples: '',
      temperature: parseFloat((data.temperature / 100).toFixed(1)),
    },
    enhance_prompt: {
      time_zone: data.timezone,
      include_time: data.time,
      include_date: data.date,
      verbosity: data.verbosity,
    },
  };
  // if (data?.enhancedPrompt) {
  //   project["enhance_prompt"] = {
  //     time_zone: data.timezone,
  //     include_time: data.time,
  //     include_date: data.date,
  //     verbosity: data.verbosity,
  //   };
  // }
  return project;
};

export const manipulateProjectInterfacePayload = (data) => {
  let project = {
    project_id: data.id,
    // access_control: data.accessControl,
    api_access: data.enableApiAccess,
    visibility: data.visibility,
    interface: {
      // enable_starters: data?.uiBuilder,
      bot_name: data.botName,
      title: data.webTitle,
      description: data.publicDescription,
      input_placeholder: data.inputBoxPlaceholder,
      disclaimer: data.desclaimerMessage,
      logging: {
        database: data.loggingEnabled?.includes('database'),
        analytics: data.loggingEnabled?.includes('analytics'),
        details: data.loggingEnabled?.includes('details'),
      },
    },
  };
  if (data.group) project['group_id'] = data.group;
  if (data?.starterGroups) {
    project['interface']['starter_groups'] = data.starterGroups;
  }
  return project;
};

export const manipulateProjectMediaPayload = (data) => {
  let search_params = {
    db_type: data?.database,
    top_k: parseInt(((data?.topK ?? 3) / 2).toFixed(0)),
    output_fields: data.showSources ? MEDIA_OUTPUT_FIELDS : [],
    prompt_mode: data?.promptMode,
    retrieval_type: data?.retrievalType,
    search_prompt: data?.searchPrompt,
    expr: data?.expressions,
  };
  // if (data?.advancedOptions) {
  //   search_params["retrieval_type"] = data?.retrievalType;
  //   search_params["search_prompt"] = data?.searchPrompt;
  //   search_params["expr"] = data?.expressions;
  // }

  let project = {
    project_id: data.id,
    // enable_search: data?.enableSearch ?? false,
    // advanced_settings: data?.advancedOptions ?? false,
    search_params,
  };
  return project;
};

export const manipulateUpdateMediaPayload = (data, isDelete) => {
  let media = {
    file_name: data.name,
    search_tags: data.tags,
    selected: isDelete ? false : data.referenced,
    visible: isDelete ? false : data.exposed,
  };
  return media;
};

export const manipulateUpdateUrlPayload = (data, isDelete) => {
  let url = {
    url_name: data.name,
    search_tags: data.tags,
    selected: isDelete ? false : data.referenced,
    visible: isDelete ? false : data.exposed,
    depth_level: data.indexDepth,
    url_document: data.docIndexing,
  };
  return url;
};

export const manipulateAutoUpdateMediaData = (olddata, data) => {
  const file = {
    ...olddata,
    size: data?.raw_size === 0 ? olddata.size : data?.size, // temp workaround for size issue
    raw_size: data?.raw_size ?? olddata.raw_size,
    uploadDate: data?.uploadDate ?? olddata.uploadDate,
    isCreated: false,
    isSearchable: data?.isSearchable ?? olddata.isSearchable,
    isFailed: data?.isFailed ?? olddata.isFailed,
    isPending: data?.isPending ?? olddata.isPending,
    statusIcon: data?.statusIcon ?? olddata.statusIcon,
    statusColor: data?.statusColor ?? olddata.statusColor,
    status: data?.status ?? olddata.status,
  };
  return file;
};

export const manipulateAutoUpdateFieldsData = (data) => {
  const title = isEmptyValue(data?.webTitle, data?.projectName);
  return {
    title,
    subtitle: data?.publicDescription,
    input: isEmptyValue(data?.inputBoxPlaceholder, 'Type a prompt'),
    desclaimer: isEmptyValue(
      data?.desclaimerMessage,
      `${title} bot may display incorrect or false information.`
    ),
  };
};
