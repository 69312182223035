import React from "react";
import { Form, Input, Select, Slider, Switch, Tooltip } from "antd";
import { LabelBox } from "..";
import {
  inputFieldRule,
  temperatureSliderformatter,
} from "../../../../../../utils";
import {
  TEMPERATURE_MARKS,
  VERBOSITY_OPTIONS,
} from "../../../../../../constants";
import { ExpandField } from "../../../../../../components";
import { TIME_ZONES } from "../../../../../../constants/timezones";
import { useSelector } from "react-redux";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUndo } from "@fortawesome/free-solid-svg-icons";

const BasicFields = ({ switchFields, switchFieldsHandler }) => {
  //REDUX DATA
  const modelOptions = useSelector(({ general }) => general.models);

  return (
    <>
      {/* PROJECT NAME */}
      <LabelBox
        title="Project name"
        name="projectName"
        message="Name of project"
      />
      <Form.Item
        name="projectName"
        id="projectName"
        rules={inputFieldRule({
          name: "Project name",
          isMax: true,
          max: 120,
        })}
      >
        <Input />
      </Form.Item>
      {/* PROJECT MODEL */}
      <div className="label-box">
        <LabelBox
          heading
          title="Model"
          // message="Enable advanced setting."
        />
        <span className="helper">
          <label htmlFor="advanced" className="helper-text">
            Advanced settings
          </label>
          <Tooltip
            title={`${
              switchFields.advanced ? "Disable" : "Enable"
            } advanced option`}
          >
            <Switch
              id="advanced"
              checked={switchFields.advanced}
              onChange={() => switchFieldsHandler("advanced")}
            />
          </Tooltip>
        </span>
      </div>
      <LabelBox
        title="Choose a Model"
        name="model"
        message="Select a model for your project"
      />
      <Form.Item
        name="model"
        rules={inputFieldRule({
          name: "Model",
          isWhiteSpace: false,
        })}
      >
        <Select
          showSearch
          id="model"
          options={modelOptions}
          getPopupContainer={(trigger) => trigger.parentNode}
          placeholder="Select model"
        />
      </Form.Item>
      {/* CUSTOMIZE INSTRUCTION */}
      <LabelBox
        name="customInstructions"
        title="Custom instructions"
        message={
          "Personalize your AI project's responses by specifying preferences or providing context. " +
          "Define elements like tone, style, and keywords to tailor behavior, enhancing relevance and satisfaction."
        }
      />
      <ExpandField title="System prompt">
        <Form.Item
          name="systemPrompt"
          rules={inputFieldRule({
            name: "System prompt",
            isRequired: false,
          })}
        >
          <Input.TextArea
            rows={5}
            placeholder="Please input your system prompt here"
          />
        </Form.Item>
      </ExpandField>
      {!!switchFields.advanced && (
        <>
          <LabelBox
            title="Temperature"
            name="temperature"
            message={
              "Temperature controls how creative or focused your project's responses are. Higher values for Creative writing and Lower values for Technical writing."
            }
          />
          <Form.Item
            id="temperature"
            name="temperature"
            rules={inputFieldRule({
              name: "Temperature",
              isWhiteSpace: false,
            })}
          >
            <Slider
              className="temperature-slider"
              marks={TEMPERATURE_MARKS}
              tooltip={{
                open: true,
                placement: "top",
                getPopupContainer: (triggerNode) => triggerNode.parentNode,
                formatter: temperatureSliderformatter,
              }}
            />
          </Form.Item>
          {/* ENHANCED PROMPT */}
          <LabelBox title="Enhanced prompt" name="enhancedPrompt" />
          <p className="sub-description">
            Enhance your prompt by configuring the timezone, date, time, and a
            verbosity level for better responses
          </p>
          <LabelBox
            title="Timezone"
            name="timezone"
            message={
              "Choose your time zone for precise date and time responses. " +
              "This helps the AI tailor outputs to your local time settings."
            }
          />
          <Form.Item
            name="timezone"
            rules={inputFieldRule({
              name: "Timezone",
              isWhiteSpace: false,
              isRequired: false,
            })}
          >
            <Select
              showSearch
              id="timezone"
              getPopupContainer={(trigger) => trigger.parentNode}
              placeholder="Select timezone"
            >
              {TIME_ZONES.map((item, index) => (
                <Select.Option key={index} value={item.utc[0]}>
                  {item.text}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="label-box form-item lg">
            <LabelBox
              name="time"
              title="Time"
              message="Determine whether the model considers the current time in its responses."
            />
            <Switch
              id="time"
              checked={switchFields.time}
              onChange={() => switchFieldsHandler("time")}
            />
          </div>
          <div className="label-box form-item lg">
            <LabelBox
              name="date"
              title="Date"
              message="Determine whether the model considers the current time in its responses."
            />
            <Switch
              id="date"
              checked={switchFields.date}
              onChange={() => switchFieldsHandler("date")}
            />
          </div>
          <LabelBox
            title="Verbosity"
            name="verbosity"
            message="Adjust the length of responses by selecting your preferred level of detail."
          />
          <Form.Item
            name="verbosity"
            rules={inputFieldRule({
              name: "Verbosity",
              isWhiteSpace: false,
              isRequired: false,
            })}
          >
            <Select
              id="verbosity"
              options={VERBOSITY_OPTIONS}
              getPopupContainer={(trigger) => trigger.parentNode}
              placeholder="Select verbosity"
            />
          </Form.Item>
          {/* ENHANCED PROMPT */}
          <div className="label-box">
            <LabelBox
              title="Enable response caching"
              name="enableCaching"
              message={
                "Cache AI responses for faster access to repeated replies" +
                " in ongoing conversations, enhancing efficiency."
              }
            />
            <Switch
              id="enableCaching"
              checked={switchFields.caching}
              onChange={() => switchFieldsHandler("caching")}
            />
          </div>
        </>
      )}
      {/* <button className="reset-btn" type="button">
        <FontAwesomeIcon icon={faUndo} />
        Reset custom instructions to ASU default
      </button> */}
    </>
  );
};

export default BasicFields;
