import React, { useEffect, useState } from "react";
import { Modal } from "../../../../components";
import { ProjectFiles, ProjectUrls } from "./components";
import {
  getProjectMediaRequest,
  getProjectMediaStatsRequest,
} from "../../../../redux/slicers/project";
import { CustomDispatch } from "../../../../helpers";
import { useSelector } from "react-redux";
import "./styles.scss";
import { cloneDeepItem } from "../../../../utils";

const KnowledgeBase = ({ preview, previewHandler }) => {
  // STATES
  const [allFiles, setAllFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [allUrls, setAllUrls] = useState([]);
  const [urls, setUrls] = useState([]);

  // REDUX DATA
  const data = useSelector(({ project }) => project.selectedProject);
  const projectMedia = useSelector(({ project }) => project.projectMedia);

  // CUSTOM DISPATCH
  const [getProjectMediaStats] = CustomDispatch(getProjectMediaStatsRequest);
  const [getProjectMedia, mediaLoader] = CustomDispatch(getProjectMediaRequest);

  // HELPERS
  const setProjectUrlsHelper = () => {
    if (!projectMedia?.urls?.length) {
      setUrls([]);
      setAllUrls([]);
      return;
    }
    let temp = [];
    cloneDeepItem(projectMedia.urls)?.forEach((url) => {
      temp.push({
        ...url,
        isUpdated: false,
      });
    });
    setAllUrls(temp);
    setUrls(temp);
  };

  const setProjectFilesHelper = () => {
    if (!projectMedia?.files?.length) {
      setAllFiles([]);
      setFiles([]);
      return;
    }
    let temp = [];
    cloneDeepItem(projectMedia.files)?.forEach((file) => {
      temp.push({
        ...file,
        isUpdated: false,
      });
    });
    setAllFiles(temp);
    setFiles(temp);
  };

  const getProjectMediaHelper = () => {
    try {
      const payload = {
        resource: "data",
        method: "list",
        details: {
          project_id: data.id,
          db_type: data.database,
        },
      };
      getProjectMedia({ payload });
      getProjectMediaStats({ payload });
    } catch (e) {
      console.error(e);
    }
  };

  // HOOKS
  useEffect(() => {
    if (!projectMedia) return;
    setProjectFilesHelper();
    setProjectUrlsHelper();
  }, [projectMedia]);

  useEffect(() => {
    if (data) getProjectMediaHelper();
  }, [data]);

  return (
    <Modal
      width={1800}
      open={preview}
      openHandler={previewHandler}
      className="project-knowledge-base"
    >
      <div className="top-header">
        <h2 className="title">Your knowledge base</h2>
        <p>
          Your knowledge base is a library of resources that your AI project can
          use to provide more accurate and helpful answers.
        </p>
      </div>
      <ProjectFiles
        allMedia={allFiles}
        media={files}
        mediaLoader={mediaLoader}
        getUploadedFiles={getProjectMediaHelper}
        setMedia={setFiles}
      />
      <ProjectUrls
        allUrls={allUrls}
        urls={urls}
        urlsLoader={mediaLoader}
        getUploadedUrls={getProjectMediaHelper}
        setUrls={setUrls}
      />
    </Modal>
  );
};

export default KnowledgeBase;
