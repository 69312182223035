import React, { useEffect, useState } from "react";
import {
  ConfirmationModal,
  Table,
  // TagsBox,
} from "../../../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faQuestionCircle,
  faEye,
  faEyeSlash,
  faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import { Checkbox, Tooltip } from "antd";
import clsx from "clsx";

const StatusColumn = ({ data }) => {
  // STATES
  const [uploadState, setUploadState] = useState({
    uploadStartTime: null,
    remainingTime: 0,
    extendedTime: 0,
  });

  // HELPERS
  const getTimeDisplay = () => {
    const { remainingTime, extendedTime } = uploadState;
    if (remainingTime > 0)
      return `${Math.floor(remainingTime / 60)}m ${
        remainingTime % 60
      }s remaining`;
    if (extendedTime > 0)
      return extendedTime < 30
        ? "Finalizing upload..."
        : extendedTime < 60
        ? "Almost there..."
        : "Taking longer than expected.";
    return "";
  };

  // HOOKS
  useEffect(() => {
    if (data.isPending && !uploadState.uploadStartTime) {
      setUploadState({
        uploadStartTime: Date.now(),
        remainingTime: data?.size >= 10240 ? 120 : data?.size >= 5120 ? 80 : 60,
        extendedTime: 0,
      });
    } else if (!data.isPending && uploadState.uploadStartTime) {
      setUploadState({
        uploadStartTime: null,
        remainingTime: 0,
        extendedTime: 0,
      });
    }
  }, [data.isPending]);

  useEffect(() => {
    if (!data.isPending || uploadState.remainingTime === 0) return;

    const timer = setInterval(() => {
      setUploadState((prev) => ({
        ...prev,
        remainingTime: Math.max(prev.remainingTime - 1, 0),
        extendedTime: prev.extendedTime + 1,
      }));
    }, 1000);

    return () => clearInterval(timer);
  }, [data.isPending, uploadState.remainingTime]);

  return (
    <>
      <span>
        <span className={clsx(data.isPending && "rotate-icon")}>
          <FontAwesomeIcon
            style={{ color: data.statusColor }}
            icon={data.statusIcon}
          />
        </span>
        <span className="status-label">{data.status}</span>
      </span>
      {data.isPending && (
        <span className="status-time">{getTimeDisplay()}</span>
      )}
    </>
  );
};

const ProjectFilesListing = ({
  isLoading,
  files = [],
  setFile,
  deleteFile,
  enableSavePreview,
  deleteLoader,
}) => {
  // STATES
  const [deleteFilePreview, setDeleteFilePreview] = useState(false);
  const [selectedData, setselectedData] = useState(null);

  // HANDLERS
  const deleteFilePreviewHandler = (data) => {
    setDeleteFilePreview(true);
    setselectedData(data);
  };
  const deleteFileHandler = () => {
    deleteFile(selectedData, () => {
      setDeleteFilePreview(false);
    });
  };
  const selectAllHandler = () => {
    const temp = [...files];
    const isAllSelected = files.every((item) => item.selected);

    temp.forEach((item) => (item.selected = !isAllSelected));
    setFile(temp);
  };
  const updateFile = (key, name) => {
    if (key !== "selected") enableSavePreview();
    let temp = [...files];
    const index = temp.findIndex((item) => item.name === name);
    temp[index][key] = !temp[index][key];
    if (!temp[index].isCreated) temp[index].isUpdated = true;
    setFile(temp);
  };
  // const addTagHandler = (data, tag) => {
  //   enableSavePreview();
  //   setFile((prevFile) => {
  //     const fileCopy = [...prevFile];
  //     const fileIndex = fileCopy.findIndex((item) => item.name === data.name);
  //     fileCopy[fileIndex].tags.push(tag);
  //     if (!fileCopy[fileIndex].isCreated) fileCopy[fileIndex].isUpdated = true;
  //     return fileCopy;
  //   });
  // };
  // const editTagHandler = (data, index, tag) => {
  //   enableSavePreview();
  //   const fileCopy = [...files];
  //   const fileIndex = fileCopy.findIndex((item) => item.name === data.name);
  //   fileCopy[fileIndex].tags[index] = tag;
  //   if (!fileCopy[fileIndex].isCreated) fileCopy[fileIndex].isUpdated = true;
  //   setFile(fileCopy);
  // };
  // const deleteTagHandler = (data, index) => {
  //   enableSavePreview();
  //   const fileCopy = [...files];
  //   const fileIndex = fileCopy.findIndex((item) => item.name === data.name);
  //   fileCopy[fileIndex].tags.splice(index, 1);
  //   if (!fileCopy[fileIndex].isCreated) fileCopy[fileIndex].isUpdated = true;
  //   setFile(fileCopy);
  // };

  // CONST VALS
  const isAllSelected =
    files.every((item) => item.selected) && files.length > 0;
  const columns = [
    {
      title: () => (
        <button onClick={selectAllHandler}>
          <Checkbox id="media-selector-all" checked={isAllSelected} />
        </button>
      ),
      dataIndex: "",
      fixed: "left",
      className: "select-cell customize-form",
      width: 25,
      render: (data) => (
        <button onClick={() => updateFile("selected", data.name)}>
          <Checkbox id={`media-selector-${data.id}`} checked={data.selected} />
        </button>
      ),
    },
    {
      title: () => <h5>Name</h5>,
      dataIndex: "",
      fixed: "left",
      className: "title-cell",
      width: 240,
      render: (data) => (
        <button style={{ cursor: "auto" }}>
          <FontAwesomeIcon className="file-icon" icon={faFile} />
          <span>{data.name}</span>
        </button>
      ),
    },
    {
      title: () => <h5>Status</h5>,
      render: (data) => {
        return <StatusColumn data={data} />;
      },
    },
    {
      title: () => <h5>Uploaded on</h5>,
      dataIndex: "uploadDate",
      className: "small-cell",
    },
    // {
    //   title: () => (
    //     <h5>
    //       Tags
    //       <Tooltip title="Assign tags to files to enable easy search and filtering." +
    //         " Use relevant keywords to categorize files for quicker access and organization.">
    //         <FontAwesomeIcon icon={faQuestionCircle} />
    //       </Tooltip>
    //     </h5>
    //   ),
    //   className: "tags-cell",
    //   render: (data) => (
    //     <TagsBox
    //       tags={data.tags}
    //       addTag={(val) => addTagHandler(data, val)}
    //       editTag={(index, val) => editTagHandler(data, index, val)}
    //       deleteTag={(index) => deleteTagHandler(data, index)}
    //     />
    //   ),
    // },
    {
      title: () => <h5>Size</h5>,
      dataIndex: "size",
      className: "small-cell",
    },
    {
      title: () => <h5>Include or exclude files</h5>,
      className: "filter-cell",
      children: [
        {
          title: () => (
            <h6>
              In Project Interactions
              <Tooltip
                title={
                  <>
                    On: The model can refer to these files in its responses.{" "}
                    <br />
                    Off: These files will not be used by the model.
                  </>
                }
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Tooltip>
            </h6>
          ),
          className: "reference-cell",
          render: (data) => (
            <button
              onClick={() => updateFile("referenced", data.name)}
              className={clsx(data.referenced && "active")}
            >
              <FontAwesomeIcon icon={data.referenced ? faEye : faEyeSlash} />
            </button>
          ),
        },
        // {
        //   title: () => (
        //     <h6>
        //       Expose as app filter
        //       <Tooltip title="Enable or disable to allow or prevent a file from being referenced in the end-user's"+
        //      " front-end application." +
        //    " Enabled files may be used in the application, while disabled files will be ignored.">
        //         <FontAwesomeIcon icon={faQuestionCircle} />
        //       </Tooltip>
        //     </h6>
        //   ),
        //   className: "expose-cell",
        //   render: (data) => (
        //     <button
        //       onClick={() => updateFile("exposed", data.name)}
        //       className={clsx(data.exposed && "active")}
        //     >
        //       <FontAwesomeIcon icon={data.exposed ? faEye : faEyeSlash} />
        //     </button>
        //   ),
        // },
      ],
    },
    {
      className: "delete-cell",
      render: (data) => (
        <button
          className="delete-btn"
          onClick={() => deleteFilePreviewHandler(data)}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </button>
      ),
    },
  ];

  return (
    <>
      <Table
        height="500px"
        className="files-listing"
        borderedTable
        data={files}
        columns={columns}
        isLoading={isLoading}
      />
      {/* DELETE MEDIA MODAL */}
      <ConfirmationModal
        title="Delete File"
        description="Are you sure you want to delete this file?"
        preview={deleteFilePreview}
        previewHandler={() => setDeleteFilePreview(false)}
        isLoading={deleteLoader}
        confirmBtnHandler={deleteFileHandler}
      />
    </>
  );
};

export default ProjectFilesListing;
