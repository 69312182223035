import { call, takeEvery, take, fork, put } from "redux-saga/effects";
import {
  callRequest,
  UPLOAD_MEDIA,
  PROJECT,
  MODELS,
  FEEDBACK,
  // GENERATE_UPLOAD_URL,
} from "../../config/web-service";
import { ALERT_TYPES } from "../../constants";
import { toastAlert } from "../../utils";
import {
  generateUploadURLRequest,
  generatePromptUploadURLRequest,
  uploadMediaRequest,
  getMediaStatusRequest,
  deleteUploadMediaRequest,
  getModelsRequest,
  getModelsRequestSuccess,
  submitFeedbackRequest,
  reportProblemRequest,
} from "../slicers/general";
import {
  manipulateGenerateUrlData,
  manipulateModelsData,
} from "../../data-manipulator/general";

// GET MEDIA STATUS
function* getMediaStatus() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(getMediaStatusRequest.type);
    try {
      const response = yield call(callRequest, {
        url: PROJECT,
        payload,
      });
      responseCallback?.(response?.status, response);
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// GENERATE UPLOAD URL
function* generateUploadUrl(action) {
  const {
    payload: { payload, responseCallback },
  } = action;
  try {
    const response = yield call(callRequest, {
      url: PROJECT,
      payload,
    });
    if (response.status) {
      responseCallback?.(
        true,
        manipulateGenerateUrlData(response.data, payload.details)
      );
      return;
    }
    responseCallback?.(false, response);
    toastAlert(response.message, ALERT_TYPES.ERROR);
  } catch (err) {
    responseCallback?.(false, err);
    toastAlert(err.message, ALERT_TYPES.ERROR);
  }
}

function* submitFeedback(action) {
  const {
    payload: { payload, responseCallback },
  } = action;
  try {
    const response = yield call(callRequest, {
      url: FEEDBACK,
      payload,
    });
    if (response.status) {
      const haveImage = payload.details.uploaded_photo;
      responseCallback?.(
        true,
        haveImage
          ? manipulateGenerateUrlData(response.data, payload.details)
          : response
      );
      return;
    }
    responseCallback?.(response?.status, response);
    toastAlert(response.message, ALERT_TYPES.ERROR);
  } catch (err) {
    responseCallback?.(false, err);
    toastAlert(err.message, ALERT_TYPES.ERROR);
  }
}

function* reportProblem(action) {
  const {
    payload: { payload, responseCallback },
  } = action;
  try {
    const response = yield call(callRequest, {
      url: FEEDBACK,
      payload,
    });
    if (response.status) {
      const haveImage = payload.details.uploaded_photo;
      responseCallback?.(
        true,
        haveImage
          ? manipulateGenerateUrlData(response.data, payload.details)
          : response
      );
      return;
    }
    responseCallback?.(response?.status, response);
    toastAlert(response.message, ALERT_TYPES.ERROR);
  } catch (err) {
    responseCallback?.(false, err);
    toastAlert(err.message, ALERT_TYPES.ERROR);
  }
}

// GENERATE UPLOAD URL
function* generatePromptUploadUrl(action) {
  const {
    payload: { payload, responseCallback },
  } = action;
  try {
    const response = yield call(callRequest, {
      url: PROJECT,
      payload,
    });
    if (response.status) {
      responseCallback?.(true, {
        queryId: response.data.query_id,
        ...manipulateGenerateUrlData(response.data, payload.details),
      });
      return;
    }
    responseCallback?.(false, response);
    toastAlert(response.message, ALERT_TYPES.ERROR);
  } catch (err) {
    responseCallback?.(false, err);
    toastAlert(err.message, ALERT_TYPES.ERROR);
  }
}

// UPLOAD MEDIA
function* uploadMedia(action) {
  const {
    payload: { url, payload, responseCallback },
  } = action;

  try {
    const urlobj = { ...UPLOAD_MEDIA, baseurl: url };
    const response = yield call(callRequest, {
      url: urlobj,
      payload,
    });
    responseCallback?.(true, response);
  } catch (err) {
    responseCallback?.(false, err);
    toastAlert(err.message, ALERT_TYPES.ERROR);
  }
}

// DELETE UPLOADED LISTING
function* deleteUploadedMedia() {
  while (true) {
    const {
      payload: { payload, responseCallback },
    } = yield take(deleteUploadMediaRequest.type);
    try {
      const response = yield call(callRequest, {
        url: PROJECT,
        payload,
      });
      responseCallback?.(response?.status, response);
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

// GET MODELS
function* getModels(action) {
  while (true) {
    const {
      payload: { responseCallback },
    } = yield take(getModelsRequest.type);
    try {
      const response = yield call(callRequest, {
        url: MODELS,
      });
      if (response.status) {
        yield put(
          getModelsRequestSuccess(manipulateModelsData(response?.data))
        );
      }
      responseCallback?.(false, response);
      !response.status && toastAlert(response.message, ALERT_TYPES.ERROR);
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

export default function* root() {
  yield takeEvery(generateUploadURLRequest.type, generateUploadUrl);
  yield takeEvery(generatePromptUploadURLRequest.type, generatePromptUploadUrl);
  yield takeEvery(uploadMediaRequest.type, uploadMedia);
  yield fork(getModels);
  yield fork(getMediaStatus);
  yield fork(deleteUploadedMedia);
  yield takeEvery(submitFeedbackRequest.type, submitFeedback);
  yield takeEvery(reportProblemRequest.type, reportProblem);
}
