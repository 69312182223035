import React, { useState, useEffect } from 'react';
import { PuffLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';

const Dropdown = ({
  optionBox = () => {},
  isLoading,
  placeholder,
  selectedVal,
  className = '',
  options = [],
  emptyText = '',
  selectHandler = () => {},
  isSelected = () => {},
  closeOnSelect = true
}) => {
  // STATES
  const [dropdownPreview, setDropdownPreview] = useState(false);

  // HANDLERS
  const dropdownPreviewHandler = () => {
    setDropdownPreview(!dropdownPreview);
  };
  const selectOptionHandler = (item) => {
    selectHandler(item);
    if (closeOnSelect) dropdownPreviewHandler();
  };

  useEffect(() => {
    // function to close dropdown preview on outside click
    document.addEventListener('click', (e) => {
      if (
        !e.target.closest(`.custom-dropdown`) &&
        !e.target.closest(`.dropdown-options`)
      ) {
        setDropdownPreview(false);
      }
    });
  }, []);
  return (
    <div
      className={`custom-dropdown ${
        dropdownPreview ? 'active' : ''
      } ${className}`}
    >
      <button
        className="selected-box"
        onClick={() => !isLoading && dropdownPreviewHandler()}
      >
        <span className="placeholder">{selectedVal ?? placeholder}</span>
        {isLoading ? (
          <PuffLoader className="toggle" size={16} color="#760033" />
        ) : (
          <FontAwesomeIcon className="toggle" icon={faChevronDown} />
        )}
      </button>
      <div className="dropdown-options">
        {options.length === 0 && (
          <button className="no-data">{emptyText}</button>
        )}
        {options.map((item, index) => (
          <button
            key={index}
            className={isSelected(item) ? 'selected' : ''}
            onClick={() => selectOptionHandler(item)}
          >
            {optionBox(item)}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
