import React from "react";
import { Modal as AntdModal } from "antd";
import "./styles.scss";

const Modal = ({
  title = "",
  className = "",
  width = 500,
  open = false,
  openHandler = () => {},
  children,
  ...rest
}) => {
  return (
    <AntdModal
      centered
      width={width}
      onCancel={openHandler}
      footer={null}
      title={null}
      open={open}
      forceRender
      className={`modal-component ${className}`}
      {...rest}
    >
      {!!title && <h2 className="modal-title">{title}</h2>}
      <div className="content-box">{children}</div>
    </AntdModal>
  );
};

export default Modal;
