import React from "react";
import { useSelector } from "react-redux";
import { Avatar } from "antd";
// import { Dropdown } from "../../../../components";
import {
  DASHBOARD_ROUTE,
  GROUPS_ROUTE,
  LEARNING_ROUTE,
  MARKETPLACE_ROUTE,
  PROJECTS_ROUTE,
} from "../../../../constants";
import clsx from "clsx";
import {
  faPenRuler,
  faBookOpen,
  faPeopleGroup,
  faSearch,
  faHome,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.scss";
import { Images } from "../../../../theme";
import { getAppEnv } from "../../../../utils";

const Sidebar = ({ preview, createProjectHandler, previewHandler }) => {
  // REDUX DATA
  const { data: userdata, groupAccess } = useSelector(({ user }) => user);

  // CONST VALS
  const currentRoute = window.location.pathname;
  const appEnv = getAppEnv();
  // const createDropdownOptions = [
  //   { label: "project", value: "project", onClick: createProjectHandler },
  //   // { label: "Template", value: "template" },
  // ];
  const navigationTabs = [
    {
      icon: faHome,
      label: "Dashboard",
      route: DASHBOARD_ROUTE,
    },
    {
      icon: faPeopleGroup,
      label: "Groups",
      route: GROUPS_ROUTE,
      onlyAdmin: true,
    },
    {
      icon: faPenRuler,
      label: "MyAI Projects",
      route: PROJECTS_ROUTE,
    },
    {
      icon: faSearch,
      label: "MyAI Marketplace",
      route: MARKETPLACE_ROUTE,
    },
    {
      icon: faBookOpen,
      label: "Learning",
      route: LEARNING_ROUTE,
    },
  ];

  const aiToolsTabs = [
    {
      icon: Images.ModelComparison,
      label: "Model Comparison",
      link: appEnv.modelComparisionHost,
    },
    {
      icon: Images.ASUGpt,
      label: "ASU GPT",
      link: appEnv.asuGptHost,
    },
  ];

  return (
    <nav
      role="navigation"
      className={clsx("dashboard-sidebar", preview && "active")}
    >
      <button className="sidebar-toggle" onClick={previewHandler}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
      <div className="mobile-box">
        {/* PROFILE BOX */}
        <div className="user-profile">
          <Avatar src={userdata?.photo_url} className="thumb">
            {userdata?.name?.charAt(0) ?? userdata?.id?.charAt(0)}
          </Avatar>
          <span className="username">{userdata.name}</span>
        </div>
        {/* CREATE NEW */}
        {/* <Dropdown
          className={`create-dropdown`}
          placeholder="Create new"
          options={createDropdownOptions}
          optionBox={(option) => option.label}
          selectHandler={({ onClick }) => onClick()}
        /> */}
      </div>
      {/* NAV ITEMS */}
      <div className="nav-menu">
        <ul>
          {navigationTabs.map((tab, index) => {
            const groupTabPreview = tab.onlyAdmin && !groupAccess;
            if (groupTabPreview) return null;
            return (
              <li key={index}>
                <Link
                  to={tab.route}
                  className={clsx(currentRoute === tab.route && "active")}
                  // onClick={previewHandler}
                >
                  <FontAwesomeIcon icon={tab.icon} className="thumb" />
                  <span className="title">{tab.label}</span>
                </Link>
              </li>
            );
          })}
        </ul>
        <ul>
          <li className="heading">Other CreateAI Apps</li>
          {aiToolsTabs.map((tab, index) => (
            <li key={index}>
              <Link target="_blank" to={tab.link}>
                <img
                  src={tab.icon}
                  alt={`${tab.label} icon`}
                  className="thumb"
                />
                <span className="title">{tab.label}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
