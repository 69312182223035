/* eslint-disable max-len */
import { Icons } from "../theme";

export const ALERT_TIMEOUT = 3000;
export const DEV_ENV = "dev";
export const PROD_ENV = "prod";
export const APP_ENV = PROD_ENV;
export const API_LOG = APP_ENV === DEV_ENV;
export const API_TIMEOUT = 29000;
export const AUTH_HOST = "https://weblogin.asu.edu/cas";
export const ASU_AUTH_DOMAIN = ".aiml.asu.edu";
export const ASU_SIGNUP_URL =
  "https://ai.asu.edu/technical-foundation/join-the-create-ai-lab-user-group";

export const DASHOBARD_SIDEBAR_PLACEMENT_RIGHT = false;
export const PROJECT_SIDEBAR_PLACEMENT_RIGHT = true;

export const MESSAGE_TYPE_JSON = true;
export const MEDIA_OUTPUT_FIELDS = [
  "source_name",
  "source_type",
  "page_number",
  "tags",
];

export const DEFAULT_PROJECT_GROUP = {
  id: "a2e3d94463e844628ed77d6bb6b4e596",
  name: "None",
};

// DATE FORMATS
export const DATE_FORMAT1 = "MMM DD, YYYY";
export const DATE_FORMAT2 = "DD, MMM YY";
export const DATE_FORMAT3 = "YYYY-MM-DD";
export const DATE_FORMAT4 = "DD-MM-YYYY";
export const DATE_FORMAT5 = "MM/DD/YYYY";
export const TIME_FORMAT1 = "hh:mma";
export const TIME_FORMAT2 = "hh:mm";
export const TIME_FORMAT3 = "hh";
export const DATE_TIME = "Do MM YYYY, hh:mm a";
export const DATE_TIME2 = "MM/DD/YYYY h:mm:ss A";

export const MESSAGE_TIMEOUT = 25; // seconds
export const MESSAGE_TIMEOUT_ERROR =
  "Hmm I'm having some trouble right now, try again in a little bit";

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// UPLOAD VALIDATION
export const MAX_FILE_SIZE = 20; // MB

// VALIDATION REGEX
export const ALLOW_ALPHABETS_REGEX = new RegExp(/^[a-zA-Z\s]*$/); // allow only alphabets and white spaces
export const ALLOW_ALPHANUM_REGEX = new RegExp(/^[a-zA-Z0-9]*$/); // allow only alphabets and numbers

// ACCESS TYPES
export const ACCESS_TYPES = {
  AUTH: "auth",
  PRIVATE: "private",
  PUBLIC: "public",
  DASHBOARD: "dashboard",
  PROJECT: "project",
};

export const SPEECH_ACCESS_PROJECTS = [
  "658b38bc546d4a0482c0e85ca913f3cc",
  "f1df1178c8a04ae1895c1991c5f01b64",
  "6561c1a3bba043818b69e074d07f27ed",
];

export const ACCESS_LIST_PAGE_SIZE = 5;

// USER ROLE TYPES
export const USER_ROLE_TYPES = {
  ADMIN: "admin",
  MEMBER: "member",
  GROUPADMIN: "groupadmin",
};

// TOAST MESSAGES
export const TOAST_MESSAGES = {
  FILE_EXIST: "File already exists",
  URL_EXIST: "URL already exists",
  FILE_UPLOAD_SUCCESS: "Files uploaded successfully",
  FILE_DELETE_SUCCESS: "File deleted successfully",
  FILE_UPDATE_SUCCESS: "Media updated successfully",
  GROUP_CREATE_SUCCESS: "Group created successfully",
  GROUP_EDIT_SUCCESS: "Group updated Successfully",
  GROUP_DELETE_SUCCESS: "Group deleted Successfully",
  ADD_USER_SUCCESS: "User added successfully",
  EDIT_USER_SUCCESS: "User role updated successfully",
  USER_REMOVE_SUCCESS: "User removed successfully",
  PROJECT_UPDATE_SUCCESS: "Project updated successfully",
  PROJECT_DELETE_SUCCESS: "Project deleted successfully",
  USER_EXSIST_AS_VIEWER: "User already added as viewer",
  CHANGES_SAVED: "Changes have been saved",
  USERS_INVITED: "Users have been invited",
  STATEMENT_REQUIRED: "At least one feedback rating is required",
  ENABLE_PUBLIC_VIEW:
    "Your AI Project is not live yet. Enable web app in the Basic tab.",
  PROJECT_COPY_SUCCESS: "Project copied successfully",
  UN_REGISTERED_USER_ERROR:
    "Please ask user to signup before inviting them as an editor",
};

// REACT TOASTIFY ALERT CONFIGURATION
export const ALERT_POSITIONS = {
  TOP_Right: "top-right",
  TOP_LEFT: "top-left",
  TOP_CENTER: "top-center",
  BOTTOM_RIGHT: "bottom-right",
  BOTTOM_LEFT: "bottom-left",
  BOTTOM_CENTER: "bottom-center",
};
export const ALERT_THEMES = {
  DARK: "dark",
  COLORED: "colored",
  LIGHT: "light",
};
export const ALERT_TYPES = {
  INFO: "info",
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  DEFAULT: "default",
};

// PUBLIC ROUTES
export const ERROR_ROUTE = "/404";
// AUTH ROUTES

// PRIVATE ROUTES
export const DASHBOARD_ROUTE = "/";
export const PROJECTS_ROUTE = "/projects";
export const PROJECT_ROUTE = "/projects/:projectslug";
export const PROJECT_ANALYTICS_ROUTE = "/projects/:projectslug/analytics";
export const GROUPS_ROUTE = "/groups";
export const GROUP_DETAIL_ROUTE = "/groups/:groupslug";
export const MARKETPLACE_ROUTE = "/marketplace";
export const LEARNING_ROUTE = "/learning";
export const APIDOC_ROUTE = "/api";

// TEMP ROLE FOR ALPHA
export const USER_ROLE = USER_ROLE_TYPES.ADMIN;

// DROPZONE FILE VALIDATION
export const DROPZONE_FILE_VALIDATION = {
  "application/pdf": [".pdf"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
  "text/plain": [".txt"],
  "application/vnd.ms-powerpoint": [".ppt"],
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": [
    ".pptx",
  ],
  // "text/html": [".html", ".htm"],
};

export const HTML_ENTITY_MAP = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "'": "&#39;",
  "/": "&#x2F;",
  "`": "&#x60;",
  "=": "&#x3D;",
};

// VISIBILITY OPTIONS
export const VISIBILITY_OPTIONS = [
  {
    label: "Private",
    value: "private",
  },
  {
    label: "Shared with the group",
    value: "group",
  },
];

// VISIBILITY OPTIONS
export const EXSISTING_GROUPS_OPTIONS = [
  {
    label: "None",
    value: "none",
  },
  {
    label: "Enterprise Technology",
    value: "Enterprise Technology",
  },
  {
    label: "EdPlus",
    value: "EdPlus",
  },
  {
    label: "Learning Enterprise",
    value: "Learning Enterprise",
  },
  {
    label: "WP Carey",
    value: "WP Carey",
  },
  {
    label: "Fulton Schools of Engineering",
    value: "Fulton Schools of Engineering",
  },
];

// USE CASES OPTIONS
export const USE_CASES_OPTIONS = [
  {
    label: "Testing",
    value: "Testing",
  },
  {
    label: "Classroom Teaching",
    value: "Classroom Teaching",
  },
  {
    label: "Course Builder",
    value: "Course Builder",
  },
  {
    label: "Knowledge Base",
    value: "Knowledge Base",
  },
  {
    label: "Chatbot",
    value: "Chatbot",
  },
  {
    label: "Other",
    value: "Other",
  },
];

// MODEL VERBOSITY OPTIONS
export const VERBOSITY_OPTIONS = [
  {
    value: "brief",
    label: "Brief",
  },
  {
    value: "detailed",
    label: "Detailed",
  },
  {
    value: "succinct",
    label: "Succinct",
  },
];

// USED MEDIA OPTION
export const USED_MEDIA_OPTIONS = [
  {
    title: "LLM Knowledge + uploaded files/media",
    value: "unrestricted",
    description:
      "The project will use both LLM's knowledge and your uploaded files/media.",
  },
  // {
  //   title: "Use Only LLM'S Knowledge",
  //   value: "both",
  //   description:
  //     "The system will rely solely on the pre-existing knowledge of the language model. No additional document or data you upload will be considered.",
  // },
  {
    title: "Only your uploaded files/media",
    value: "restricted",
    description:
      "The project will focus exclusively on your uploaded files/media, completely ignoring pre-existing knowledge of the project's LLM.",
  },
  {
    title: "Custom Search Mode",
    value: "custom",
    description:
      "The system will use information from your prompts to retrieve relevant information from your uploaded files/media.",
  },
];

// MEDIA RETRIEVAL OPTION
export const MEDIA_RETRIEVAL_OPTIONS = [
  {
    label: "Chunk",
    value: "chunk",
  },
  {
    label: "Neighbor",
    value: "neighbor",
  },
  {
    label: "Document",
    value: "document",
  },
];

// DEFAULT SEARCH PROMPT
export const DEFAULT_SEARCH_PROMPT =
  "You have a Knowledge Base which you need to use to answer any Question the user has. If a Question asked by the user is not in the provided Knowledge Base, simply reply that, you cannot answer that since it does not exist in the provided Knowledge Base. Do not mention the existence of a Knowledge Base to the user. \n\nKnowledge Base:{data}\n\nQuestion:{query}";

// DATABASE OPTIONS
export const DATABASE_OPTIONS = [
  {
    label: "Milvus",
    value: "milvus",
    description:
      "A pure vector database that offers notable advantages for handling high-dimensional vector data efficiently, making it particularly well-suited for tasks involving similarity search and analysis. Its robust capabilities empower users to navigate and retrieve information in complex vector spaces effectively and is extremely fast even with large datasets. However, it only supports a single tag for the files therefore caution should be used if multifaceted filtering is needed.",
  },
  {
    label: "Opensearch",
    value: "opensearch",
    description:
      " OpenSearch is a hybrid vector database with advanced vector-based data handling. It efficiently manages high-dimensional vector data along with the raw text, making it suitable for diverse applications such as similarity search and recommendation systems. It also supports ability to add unlimited metadata and tags. However caution should be used if this is chosen for extremely large amounts of data as the similarity search performance may be slower.",
  },
];

// LOGGING OPTIONS
export const LOGGING_OPTIONS = [
  {
    label: "Database",
    value: "database",
  },
  {
    label: "Analytics",
    value: "analytics",
  },
  {
    label: "Prompt Details",
    value: "details",
  },
];

// ROLE OPTIONS
export const ROLE_OPTIONS = [
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Member",
    value: "member",
  },
];

export const PROJECT_ROLES = {
  VIEWER: "viewer",
  OWNER: "owner",
  EDITOR: "editor",
};

// TEMPERATURE MARKS
export const TEMPERATURE_MARKS = {
  0: "0.1",
  100: "1.0",
};

// TOP K MARKS
export const TOPK_MARKS = {
  0: "0",
  100: "50",
};

// PROJECT ROLES OPTIONS
export const PROJECT_ROLES_OPTIONS = [
  {
    label: "Can view",
    value: PROJECT_ROLES.VIEWER,
  },
  {
    label: "Can edit",
    value: PROJECT_ROLES.EDITOR,
  },
];

// PROJECT STATUS OPTIONS
export const PROJECT_STATUS_OPTIONS = [
  {
    label: "Draft",
    value: "draft",
  },
  {
    label: "Running",
    value: "running",
  },
  { label: "Error", value: "error" },
];
// CREATE NEW DROPDOWN OPTIONS
export const CREATE_NEW_OPTIONS = [
  { label: "project", value: "project" },
  { label: "Template", value: "template" },
];

// PROJECTS SORT OPTIONS
export const PROJECT_SORT_OPTIONS = [
  { label: "most recent", value: "most_recent" },
  { label: "live", value: "live" },
];
export const PROJECT_STATUS_CLASSES = {
  error: "error",
  live: "live",
};

export const PROJECT_SECTIONS = {
  RECENT: "recent",
  CREATE: "create",
  SHARED: "shared",
};

export const CREATE_CARD_TYPES = {
  COURSE_MATERIAL: "Course materials",
  STUDENT_ENGAGEMENT: "Student engagement",
  DOCUMENT_TOOLS: "Document tools",
  OPERATIONS: "Operations",
  EXPLORATION: "Exploration",
};

export const CREATE_PROJECT_DATA = [
  {
    title: "Lesson Creator",
    type: CREATE_CARD_TYPES.COURSE_MATERIAL,
    desc: "Develop structured lesson plans customized to your curriculum.",
    favorite: true,
  },
  {
    title: "Assessment Tools",
    type: CREATE_CARD_TYPES.COURSE_MATERIAL,
    desc: "Create effective assessments with ease.",
    favorite: true,
  },
  {
    title: "Lecture preparation",
    type: CREATE_CARD_TYPES.COURSE_MATERIAL,
    desc: "Prepare detailed lecture notes with key points and examples.",
    favorite: true,
  },
  {
    title: "Engaging Discussions",
    type: CREATE_CARD_TYPES.STUDENT_ENGAGEMENT,
    desc: "Generate prompts to start meaningful conversations.",
    favorite: true,
  },
  {
    title: "Question Maker",
    type: CREATE_CARD_TYPES.STUDENT_ENGAGEMENT,
    desc: "Create thoughtful questions for any topic.",
    favorite: true,
  },
  {
    title: "Real-World Links",
    type: CREATE_CARD_TYPES.STUDENT_ENGAGEMENT,
    desc: "Connect lessons to real-world examples.",
    favorite: true,
  },
  {
    title: "Student Feedback",
    type: CREATE_CARD_TYPES.STUDENT_ENGAGEMENT,
    desc: "Provide feedback on student assignments and projects.",
    favorite: true,
  },
  {
    title: "Interactive docs",
    type: CREATE_CARD_TYPES.DOCUMENT_TOOLS,
    desc: "Easily upload, search, and interact with documents.",
    favorite: true,
  },
  {
    title: "Text Simplifier",
    type: CREATE_CARD_TYPES.DOCUMENT_TOOLS,
    desc: "Rewrite text complexity to suit your audience.",
    favorite: true,
  },
  {
    title: "Grant Proposal Writing",
    type: CREATE_CARD_TYPES.DOCUMENT_TOOLS,
    desc: "Write compelling grant proposals for research funding.",
    favorite: true,
  },
  {
    title: "Academic Writing",
    type: CREATE_CARD_TYPES.DOCUMENT_TOOLS,
    desc: "Write and edit academic papers with clarity and coherence.",
    favorite: true,
  },
  {
    title: "Support Chatbot",
    type: CREATE_CARD_TYPES.OPERATIONS,
    desc: "Provide instant, automated assistance to customer inquiries.",
    favorite: true,
  },
  {
    title: "Smart Search",
    type: CREATE_CARD_TYPES.OPERATIONS,
    desc: "Efficiently search through knowledge bases.",
    favorite: true,
  },
  {
    title: "Content Creation",
    type: CREATE_CARD_TYPES.OPERATIONS,
    desc: "Design engaging educational, and training materials.",
    favorite: true,
  },
  {
    title: "Meeting Agendas",
    type: CREATE_CARD_TYPES.OPERATIONS,
    desc: "Plan agendas for faculty and department meetings.",
    favorite: true,
  },
  {
    title: "Knowledge Booster",
    type: CREATE_CARD_TYPES.EXPLORATION,
    desc: "Refresh your knowledge in various subjects.",
    favorite: true,
  },
  {
    title: "Research Assistance",
    type: CREATE_CARD_TYPES.EXPLORATION,
    desc: "Find and summarize relevant research papers and studies.",
    favorite: true,
  },
];

export const PROJECT_TEMPLATE_ICONS = {
  Favorites: Icons.LikeFilled,
  [CREATE_CARD_TYPES.COURSE_MATERIAL]: Icons.CourseMaterial,
  [CREATE_CARD_TYPES.STUDENT_ENGAGEMENT]: Icons.StudentEngagement,
  [CREATE_CARD_TYPES.DOCUMENT_TOOLS]: Icons.DocTool,
  [CREATE_CARD_TYPES.OPERATIONS]: Icons.Operations,
  [CREATE_CARD_TYPES.EXPLORATION]: Icons.Exploration,
};

export const HELP_DOC_URL =
  "https://docs.google.com/document/d/11h6NtIfTS58GDCMa3uhmC2L4UD6de8tPBCzFQNA9uEU/edit?tab=t.0";
